import Mapbox from "mapbox-gl";
import React from "react";
import MapboxGeocoder, {
  GeocoderOptions,
  Result
} from "@mapbox/mapbox-gl-geocoder";
import circle from "@turf/circle";
import bbox from "@turf/bbox";
import scale from "@turf/transform-scale";
import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const DEFAULT_OPTIONS: UseMapboxGeocoderOptions = {
  // restrict results to UK
  bbox: [
    -12.810059,
    49.724479,
    2.065430,
    59.756395
  ],
  // don't add a marker on result selection
  marker: false
};

export const useMapboxGeocoder = (
  map: Mapbox.Map | undefined,
  radius: number,
  options: UseMapboxGeocoderOptions = DEFAULT_OPTIONS
): MapboxGeocoder | undefined => {
  const [ geocoder, setGeocoder ] = React.useState<MapboxGeocoder>();

  React.useEffect(() => {
    if (!map || geocoder) {
      return;
    }

    // Initialize the geocoder
    const geocoderInstance = new MapboxGeocoderFixed({
      mapboxgl: Mapbox,
      accessToken: Mapbox.accessToken,
      ...options
    });

    setGeocoder(geocoderInstance);
    map.addControl(geocoderInstance);
  }, [ map ]);

  // move map to centre on search result selection
  React.useEffect(() => {
    if (!map || !geocoder) {
      return;
    }

    const handleGeocodeResult = ({ result }: GeocoderEvent) => {
      const newCentralArea = circle(result.center, radius, { steps: 4 });
      const reasonableMapBoundingBox = bbox(scale(newCentralArea, 2));

      map.fitBounds(reasonableMapBoundingBox as BBox2d);
    };

    // Add result listener
    geocoder.on("result", handleGeocodeResult);

    // Remove listener
    return () => {
      geocoder.off("result", handleGeocodeResult);
    };
  }, [
    map,
    geocoder,
    radius
  ]);

  return geocoder;
};

// TYPES

interface GeocoderOptionsFixed extends Omit<GeocoderOptions, "mapboxgl"> {
  mapboxgl?: typeof Mapbox;
}

export class MapboxGeocoderFixed extends MapboxGeocoder {
  constructor(options: GeocoderOptionsFixed) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    super(options as any);
  }
}

type UseMapboxGeocoderOptions = Omit<GeocoderOptionsFixed, "mapboxgl" | "accessToken">;

interface GeocoderEvent {
  result: Result;
}