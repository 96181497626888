import {
  Anchor,
  Box,
  Heading,
  Text
} from "grommet";
import { CircleQuestion } from "grommet-icons";

export const PageNotFound: React.FC = () => {
  return (
    <Box fill align="center" pad="medium">
      <CircleQuestion size="xlarge" color="primary"/>
      <Heading>Page Not Found</Heading>
      <Text>The page you're looking for doesn't seem to exist. Are you sure that the URL is correct?</Text>
      <Anchor href="/dashboards" label="Back to all Dashboards" margin={{ top: "medium" }}/>
    </Box>
  );
};