import { TextInput as GrommetTextInput } from "grommet";
import styled from "styled-components";

export const TextInput = styled(GrommetTextInput)`
  font-family: 'Merriweather Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  background-color: white;
`;