import mapboxgl from "mapbox-gl";

import { GeoJSONDemographicsFeature } from "src/models/DemographicsData";
import { PoliticalFeatureProperties } from "src/models/PoliticsData";
import { colours } from "src/theme/copper-theme";
import {
  addMapDataSource,
  addMapLayer,
  showMapLayer,
  hideMapLayer
} from "src/utils/map-data-helper";

interface DemographicsAreaMapMetadata {
  type: "council" | "ward";
  sourceId: string;
  fillId: string;
  strokeId: string;
}

export const getDemographicsAreaMapMetadata = (featureProperties: PoliticalFeatureProperties): DemographicsAreaMapMetadata => {
  const type = featureProperties.parent_area === null ? "council" : "ward";

  return {
    type,
    sourceId: `demographics-${type}-${featureProperties.id}-source`,
    fillId: `demographics-${type}-${featureProperties.id}-fill`,
    strokeId: `demographics-${type}-${featureProperties.id}-stroke`
  };
};

export const addDemographicsAreaToMap = (map: mapboxgl.Map, feature: GeoJSONDemographicsFeature): void => {
  const {
    type,
    sourceId,
    fillId,
    strokeId
  } = getDemographicsAreaMapMetadata(feature.properties);

  const initialVisibility = type === "ward" ? "none" : "visible";
  const fillColor = type === "ward" ? "rgba(128, 128, 128, 0.2)" : "rgba(200, 200, 200, 0.2)";
  const strokeColor = type === "ward" ? "dimgrey" : "#808080";
  const strokeWidth = type === "ward" ? 2 : 3;

  addMapDataSource(map, sourceId, feature);

  // add semi-transparent fill
  addMapLayer(map, {
    id: fillId,
    type: "fill",
    source: sourceId,
    layout: { visibility: initialVisibility },
    paint: { "fill-color": fillColor }
  });

  // add stroke
  addMapLayer(map, {
    id: strokeId,
    type: "line",
    source: sourceId,
    layout: { visibility: initialVisibility },
    paint: {
      "line-color": strokeColor,
      "line-width": strokeWidth,
      "line-opacity": 0.5
    }
  });
};

export const showDemographicsArea = (map: mapboxgl.Map, featureProperties: PoliticalFeatureProperties): void => {
  const { fillId, strokeId } = getDemographicsAreaMapMetadata(featureProperties);

  showMapLayer(map, fillId);
  showMapLayer(map, strokeId);
};

export const hideDemographicsArea = (map: mapboxgl.Map, featureProperties: PoliticalFeatureProperties): void => {
  const { fillId, strokeId } = getDemographicsAreaMapMetadata(featureProperties);

  hideMapLayer(map, fillId);
  hideMapLayer(map, strokeId);
};

export const updateWardHighlight = (map: mapboxgl.Map, featureProperties: PoliticalFeatureProperties, shouldHighlight: boolean): void => {
  const { fillId, strokeId } = getDemographicsAreaMapMetadata(featureProperties);

  map.setPaintProperty(fillId, "fill-color", shouldHighlight ? "rgba(60, 200, 200, 0.6)" : "rgba(128, 128, 128, 0.2)");
  map.setPaintProperty(strokeId, "line-color", shouldHighlight ? colours.aquamarineDream : "dimgrey");
};