import React, {
  RefObject,
  useRef
} from "react";
import {
  Anchor,
  BoxTypes,
  Card as GrommetCard,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Box,
  Image
} from "grommet";
import { Download } from "grommet-icons";
import { exportComponentAsPNG } from "react-component-export-image";
import styled from "styled-components";

import { Text } from "src/components/text/Text";

interface CardProps extends BoxTypes {
  /**
   * Card header contents
   */
  headerText: string;
  bodyText?: string;
  dataSource: {
    logo?: string;
    name: string;
    link: string;
  }
  innerRef?: RefObject<HTMLDivElement>;
  fileName?: string;
  cardSize?: string;
}

/**
 * A card for basic data presentation
 */
export const Card: React.FC<CardProps> = ({
  headerText,
  bodyText,
  dataSource,
  children,
  fileName,
  cardSize,
  ...props
}: CardProps) => {
  const imageExportRef = useRef<HTMLDivElement>(null);

  return (
    <GrommetCard {...props}>
      <CardHeader pad="small">
        <Text as="h2">{headerText}</Text>
      </CardHeader>
      <CardBody
        style={{ height: cardSize }}
        pad={{
          vertical: "xxsmall",
          horizontal: "medium"
        }}
      >
        <RelativeWrapper ref={imageExportRef} style={{ height: cardSize ? "inherit" : undefined }}>
          {children}
        </RelativeWrapper>
        {bodyText && (
          <Box pad={{ top: "medium" }} align="center">
            <Text as="small">{bodyText}</Text>
          </Box>
        )}
      </CardBody>
      <CardFooter pad="small">
        <Anchor
          size="small"
          href={dataSource.link}
          label={dataSource.name}
          icon={<Image height="30" src={dataSource.logo}/>}
          target="_blank"
          aria-label={`Link to dataset ${dataSource.name} (opens in new tab)`}
        />
        <Button icon={<Download />} onClick={() => exportComponentAsPNG(imageExportRef, { fileName })} aria-label="Download Chart Image (opens in new window)"/>
      </CardFooter>
    </GrommetCard>
  );
};

const RelativeWrapper = styled.div`
  position: relative;
`;