import React from "react";
import {
  Box,
  Button as GrommetButton,
  Grid,
  Image,
  Spinner,
  Layer,
  Card,
  CardHeader,
  CardBody
} from "grommet";
import {
  Search,
  Add,
  Group,
  Logout,
  CircleInformation
} from "grommet-icons";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

import { Text } from "src/components/text/Text";
import { TextInput } from "src/components/form/TextInput";
import { Error } from "src/components/error/Error";
import { PageLayout } from "src/components/layout/PageLayout";
import { ImageCard } from "src/components/card/ImageCard";
import { Dashboard } from "src/models/Dashboard";
import { Confirmation } from "src/components/modal/Confirmation";
import { useDashboardsApi } from "src/utils/api/dashboards.api";
import { isAdmin } from "src/utils/user-helper";
import { useAuthContext } from "src/hooks/useAuthContext";
import { Button } from "src/components/button/Button";

export const DashboardsScreen: React.FC = () => {
  const history = useHistory();
  const { setAuthDataState } = useAuthContext();
  const [ getDashboardsResponse, getDashboardsRequest ] = useDashboardsApi("GET_DASHBOARDS");
  const [ filteredDashboards, setFilteredDashboards ] = React.useState<Dashboard[]>([]);
  const [ dashboardToDelete, setDashboardToDelete ] = React.useState<Dashboard | null>(null);
  const [ showDeleteModal, setShowDeleteModal ] = React.useState(false);
  const [ deleteDashboardResponse, deleteDashboardRequest ] = useDashboardsApi("DELETE_DASHBOARD");
  const [ userIsAdmin, setUserIsAdmin ] = React.useState(false);
  const [ showInfoModal, setShowInfoModal ] = React.useState(false);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then((user: CognitoUser) => {
      setUserIsAdmin(isAdmin(user));
    });
  }, []);

  React.useEffect(() => {
    getDashboardsRequest({});
  }, []);

  React.useEffect(() => {
    if (getDashboardsResponse.data) {
      setFilteredDashboards(getDashboardsResponse.data);
    }
  }, [ getDashboardsResponse.data ]);

  const filterDashboards = (filterString: string) => {
    const allDashboards = getDashboardsResponse.data || [];

    const filtered = allDashboards.filter(dashboard => (
      dashboard.name.toLowerCase().includes(filterString.toLowerCase())
    ));

    setFilteredDashboards(filtered);
  };

  const handleDashboardClick = (id: number) => {
    history.push(`dashboards/${id}`);
  };

  const handleNewDashboardClick = () => {
    history.push("dashboards/new");
  };

  const handleDeleteDashboardClick = () => {
    if (dashboardToDelete) {
      deleteDashboardRequest({ id: dashboardToDelete.id.toString(10) });
    }
  };

  React.useEffect(() => {
    if (deleteDashboardResponse.complete && !deleteDashboardResponse.error) {
      setShowDeleteModal(false);
      history.go(0);
    }
  }, [ deleteDashboardResponse ]);

  const generateDashboardThumbnail = ({ location: { latitude, longitude } }: Dashboard) => (
    `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${longitude},${latitude},11/600x400?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
  );

  const handleLogout = async () => {
    await Auth.signOut();

    setAuthDataState({
      authenticated: false,
      authenticating: false,
      error: null,
      idToken: ""
    });
  };

  const sortDashboards = (a: Dashboard, b: Dashboard) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  };

  if (getDashboardsResponse.error) {
    return (
      <Error error={getDashboardsResponse.error} />
    );
  }

  if (getDashboardsResponse.pending || !getDashboardsResponse.data) {
    return (
      <Box align="center" fill="vertical" justify="center">
        <Spinner size="large" />
      </Box>
    );
  }

  return (
    <PageLayout
      title="Communify Insight"
      HeaderRight={(
        <HeaderRightWrapper>
          <TextInput
            size="small"
            placeholder="Search for a dashboard..."
            icon={<Search size="20px" />}
            onChange={event => filterDashboards(event.target.value)}
            title="Search for a dashboard"
            tabIndex={0}
          />
          <GrommetButton
            icon={<Add color="brand" />}
            onClick={handleNewDashboardClick}
            aria-label="Create New Dashboard"
          />
          {userIsAdmin && (
            <GrommetButton
              icon={<Group color="brand" />}
              onClick={() => history.push("/users")}
              aria-label="Manage Users"
            />
          )}
          <GrommetButton
            icon={<CircleInformation color="brand" />}
            onClick={() => setShowInfoModal(true)}
            aria-label="Info"
          />
          <GrommetButton
            icon={<Logout color="brand" />}
            onClick={handleLogout}
            aria-label="Logout"
          />
        </HeaderRightWrapper>
      )}
    >
      <SearchWrapper>
        <TextInput
          size="small"
          style={{ backgroundColor: "white" }}
          placeholder="Search for a dashboard..."
          icon={<Search />}
          onChange={event => filterDashboards(event.target.value)}
          title="Search for a dashboard"
          tabIndex={0}
        />
      </SearchWrapper>
      <DashboardsWrapper>
        {filteredDashboards.length > 0 ? (
          <StyledGrid
            pad="medium"
            gap={{
              row: "3rem",
              column: "3rem"
            }}
            justifyContent="between"
            columns={{
              count: "fill",
              size: "medium"
            }}
          >
            {(filteredDashboards.sort(sortDashboards)).map(dashboard => (
                <ImageCard
                  articlePolymorphic
                  key={dashboard.id}
                  headerText={dashboard.name}
                  canDelete
                  onClick={() => handleDashboardClick(dashboard.id)}
                  onDelete={() => {
                    setDashboardToDelete(dashboard);
                    setShowDeleteModal(true);
                  }}
                >
                  <Image
                    tabIndex={0}
                    src={generateDashboardThumbnail(dashboard)}
                    width={"100%"}
                    alt={`Dashboard for ${dashboard.name}`}
                    style={{ borderRadius: "0.75rem 0.75rem 0rem 0rem" }}
                  />
                </ImageCard>
            ))}
          </StyledGrid>
        ) : (
          <Text as="h2">No dashboards yet - why not create one?</Text>
        )}
        {showDeleteModal && (
          <Layer
            onEsc={() => setShowDeleteModal(false)}
            onClickOutside={() => setShowDeleteModal(false)}
          >
            <Confirmation
              title={`Are you sure you want to delete "${dashboardToDelete?.name}"?`}
              onSubmit={handleDeleteDashboardClick}
              submitText={"Delete"}
              onCancel={() => setShowDeleteModal(false)}
              cancelText={"Cancel"}
              pending={deleteDashboardResponse.pending}
            />
          </Layer>
        )}
        {showInfoModal && (
          <Layer
            onEsc={() => setShowInfoModal(false)}
            onClickOutside={() => setShowInfoModal(false)}
          >
            <Card width="large" pad="large">
              <CardHeader direction="column" align="center">
                <Text as="h2" bold>
                  Information about Communify Insight
                </Text>
              </CardHeader>
              <CardBody pad="medium" align="center">
                <InfoModalText>
                  Communify Insight is a tool that provides data about a community.
                  It gives you the tools you need to be able to understand a community in a meaningful way.
                </InfoModalText>
                <InfoModalText>
                  The data you’ll find here provides points of insight that support you in drawing smart conclusions and recommendations which can form a communications strategy.
                </InfoModalText>
                <InfoModalText>
                  This tool allows you to understand more about the socio-economic, geographical and political elements of a community.
                </InfoModalText>
              </CardBody>
              <Box alignSelf="center">
                <Button primary label={"Close"} onClick={() => setShowInfoModal(false)} />
              </Box>
            </Card>
          </Layer>
        )}
      </DashboardsWrapper>
    </PageLayout>
  );
};

const HeaderRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & > div:first-child {
    width: 36rem;
    margin-right: 0.5rem;
    display: none;
    @media (min-width: 1280px) {
      display: block;
    }
  }
`;

const SearchWrapper = styled.div`
  z-index: 1;
  padding: 1.4rem 2.4rem;
  background-color: #ffffffaa;
  box-shadow: 0 0 0.625rem 0.125rem rgb(0 0 0 / 10%);
  @media (min-width: 1280px) {
    display: none;
  }
`;

const DashboardsWrapper = styled.div`
  overflow-y: scroll;
`;

const StyledGrid = styled(Grid)`
  grid-template-columns: repeat(auto-fill, min(calc((100% - 3rem - 4.8rem) / 2), 100%));
  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, min(calc((100% - 6rem - 4.8rem) / 3), 100%));
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(auto-fill, min(calc((100% - 9rem - 4.8rem) / 4), 32rem));
  }
`;

const InfoModalText = styled(Text).attrs({ as: "span" })`
  text-align: center;
  margin-bottom: 2rem;
`;