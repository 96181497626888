import mapboxgl from "mapbox-gl";

import { LayerVisibility } from "src/utils/map-data-helper";

import { greenspaceFiltersByCategory } from "./greenspace";
import { healthcareFiltersByCategory } from "./healthcare";
import { educationFiltersByCategory } from "./education";

const geographyLayers: Record<string, string[]> = {
  greenspace: Object.keys(greenspaceFiltersByCategory),
  healthcare: Object.keys(healthcareFiltersByCategory),
  education: Object.keys(educationFiltersByCategory)
};

export const toggleGeographyLayer = (map: mapboxgl.Map, featureToUpdate: string, visibility: LayerVisibility): void => {
  if (geographyLayers[ featureToUpdate ]) {
    geographyLayers[ featureToUpdate ].map(layer => {
      map.setLayoutProperty(layer, "visibility", visibility);
    });
  } else {
    map.setLayoutProperty(featureToUpdate, "visibility", visibility);
  }
};

export * from "./greenspace";
export * from "./healthcare";
export * from "./education";