import { circle } from "@turf/turf";
import Mapbox from "mapbox-gl";
import React from "react";

import { copperCharts } from "src/theme/copper-theme";

import { MapEvent } from "./useMapbox";

const updateMarker = (marker: Mapbox.Marker, location: Mapbox.LngLat) => {
  marker.setLngLat(location);
};

const addRadiusLayer = (map: Mapbox.Map) => {
  // Add a placeholder data source for the radius
  map.addSource("radius", {
    type: "geojson",
    data: ""
  });

  // Style the map layer for the pin radius
  map.addLayer({
    type: "fill",
    id: "radiusLayer",
    source: "radius",
    minzoom: 8,
    paint: {
      "fill-color": "rgba(54, 92, 115, 0.05)",
      "fill-outline-color": copperCharts[ 0 ],
      "fill-opacity-transition": { duration: 200 },
      "fill-opacity": 1
    }
  });
};

const updateRadius = (map: Mapbox.Map, radius: number) => {
  const centre = map.getCenter().toArray();
  // generate updated radius feature
  const radiusFeature = circle(centre, radius, { steps: 60 });

  radiusFeature.id = 0;
  // get data source
  const dataSource = map.getSource("radius") as Mapbox.GeoJSONSource;

  if (dataSource) {
    // update data source with new radius
    dataSource.setData(radiusFeature);
  }
};

const updateCentralArea = (map: Mapbox.Map, marker: Mapbox.Marker, radius: number) => {
  updateRadius(map, radius);
  updateMarker(marker, map.getCenter());
};

export const useMapboxCentralArea = (map: Mapbox.Map | undefined, radius: number): Mapbox.Marker => {
  const marker = React.useRef<Mapbox.Marker>(new Mapbox.Marker({ color: copperCharts[ 0 ] }));
  const [ initialised, setInitialised ] = React.useState(false);

  // initialise marker + radius on map load
  React.useEffect(() => {
    if (!map) {
      return;
    }

    const initaliseCentralArea = () => {
      // add radius to map
      addRadiusLayer(map);
      // instantiate central area
      updateCentralArea(map, marker.current, radius);
      // add marker to map
      marker.current.addTo(map);
      setInitialised(true);
    };

    map.on("load", initaliseCentralArea);

    return () => {
      map.off("load", initaliseCentralArea);
    };
  }, [ map, radius ]);

  // update marker + radius on map update
  React.useEffect(() => {
    if (!map || !initialised) {
      return;
    }

    const handleMapUpdate = ({ target: map }: MapEvent) => {
      updateCentralArea(map, marker.current, radius);
    };

    const handleMapMoveStart = ({ target: map }: MapEvent) => {
      if (map.getLayer("radiusLayer")) {
        // hide radius on move start
        map.setPaintProperty("radiusLayer", "fill-opacity", 0);
      }
    };

    const handleMapMoveEnd = ({ target: map }: MapEvent) => {
      if (map.getLayer("radiusLayer")) {
        // show radius on move end
        map.setPaintProperty("radiusLayer", "fill-opacity", 1);
      }
    };

    // update area on drag + zoom events
    map.on("move", handleMapUpdate);
    map.on("zoom", handleMapUpdate);
    // show/hide radius whilst moving
    map.on("movestart", handleMapMoveStart);
    map.on("moveend", handleMapMoveEnd);

    return () => {
      map.off("move", handleMapUpdate);
      map.off("zoom", handleMapUpdate);
      map.off("movestart", handleMapMoveStart);
      map.off("moveend", handleMapMoveEnd);
    };
  }, [
    map,
    initialised,
    radius
  ]);

  // re-draw area on radius change
  React.useEffect(() => {
    if (!map) {
      return;
    }

    updateRadius(map, radius);
  }, [ map, radius ]);

  return marker.current;
};