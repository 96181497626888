import React from "react";
import { Bar } from "react-chartjs-2";

import { Card } from "src/components/card/Card";
import { useActiveDashboardSlug } from "src/hooks/useActiveDashboardSlug";
import { useChartTooltip } from "src/hooks/useTooltip";
import {
  ChartData,
  ChartProps
} from "src/models/ChartData";
import {
  getBaselineData,
  getBaselineDataset
} from "src/utils/chart-data-helper";

import { Tooltip } from "./Tooltip";
import onsLogo from "../../assets/ons-logo.png";

export const AgeBar: React.FC<ChartProps> = ({ data, withBaseline }) => {
  const dashboardSlug = useActiveDashboardSlug();
  const [ tooltipData, tooltipConfig ] = useChartTooltip<"bar">();
  const baselineData = React.useMemo(() => withBaseline ? getBaselineData("age") : null, [ withBaseline ]);

  const chartData = React.useMemo(() => {
    if (baselineData) {
      const baselineDataset = getBaselineDataset(baselineData);

      if (data && baselineDataset) {
        const dataWithBaseline: ChartData = {
          ...data,
          datasets: [ ...data.datasets, baselineDataset ]
        };

        return dataWithBaseline;
      }

      return data;
    }
  }, [ baselineData ]);

  const BarChart = React.useMemo(() => (
    <Bar
      data={chartData}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: "y",
        scales: { y: { ticks: { font: { size: 12 } } } },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 30,
              font: { size: 11 }
            }
          },
          tooltip: tooltipConfig
        }
      }}
    />
  ), [ data ]);

  return (
    <Card
      headerText="Age"
      dataSource={{
        logo: onsLogo,
        name: "NOMIS QS103EW (2011)",
        link: "https://www.nomisweb.co.uk/query/construct/summary.asp?reset=yes&mode=construct&dataset=503&version=0&anal=1&initsel="
      }}
      fill="horizontal"
      fileName={`age-${dashboardSlug}`}
      cardSize="50vh"
    >
      {BarChart}
      <Tooltip data={tooltipData} baselineData={baselineData} />
    </Card>
  );
};