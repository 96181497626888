import styled, {
  css,
  DefaultTheme,
  ThemedStyledProps
} from "styled-components";

export interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
    bold?: boolean;
    uppercase?: boolean;
    // use as keyword to switch tag + add styling
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "a" | "small" | "label" | "dd" | "dt" | "li";
    color?: keyof DefaultTheme["colors"];
    textTransform?: "uppercase" | "lowercase" | "sentence" | "capitalise";
  }

export const Text = styled.span<TextProps>`
    font-family: 'Merriweather Sans', -apple-system, 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
    font-style: normal;
    text-transform: ${({ uppercase }) => uppercase ? "uppercase" : "none"};
    letter-spacing: ${({ uppercase }) => uppercase ? "0.2rem" : "inherit"};
    cursor: ${({ onClick }) => onClick ? "pointer" : "inherit"};
    color: ${getTextColor};
  
    ${getFontStyle}
    ${getTextTransform}
  `;

function getTextColor({ theme, color }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!color) {
    return "inherit";
  }

  return theme.colors[ color ];
}

function getFontStyle({
  as, bold, color, theme
}: ThemedStyledProps<TextProps, DefaultTheme>) {
  switch (as) {
    case "h1":
      return css`
          font-family: 'Merriweather', 'Georgia', serif;
          font-size: 2.2rem;
          line-height: 1.4;
          font-weight: 700;
        `;

    case "h2":
      return css`
          font-family: 'Merriweather', 'Georgia', serif;
          font-size: 1.8rem;
          line-height: 1.4;
          font-weight: 700;
        `;

    case "h3":
      return css`
          font-family: 'Merriweather', 'Georgia', serif;
          font-size: 1.6rem;
          line-height: 1.4;
          font-weight: 700;
        `;

    case "h4":
      return css`
          font-family: 'Merriweather', 'Georgia', serif;
          font-size: 1.4rem;
          line-height: 1.4;
          font-weight: ${bold ? 700 : 400};
        `;

    case "h5":
      return css`
          font-family: 'Merriweather', 'Georgia', serif;
          font-size: 1.2rem;
          line-height: 1.4;
          font-weight: ${bold ? 700 : 400};
        `;

    case "label":
      return css`
          font-size: 1.4rem;
          line-height: 1.4;
          font-weight: ${bold ? 700 : 400};
        `;

    case "small":
      return css`
          font-size: 1.2rem;
          line-height: 1.4;
          font-weight: ${bold ? 700 : 400};
        `;

    case "a":
      return css`
          font-size: 1.4rem;
          line-height: 1.4;
          cursor: pointer;
          text-decoration: underline;
          font-weight: ${bold ? 700 : 400};
          color: ${color ? color : theme.colors.primary};
        `;

    case "p":
    case "span":
    case "li":
    default:
      return css`
          font-size: 1.6rem;
          line-height: 1.4;
          font-weight: ${bold ? 700 : 400};
        `;
  }
}

function getTextTransform({ textTransform }: ThemedStyledProps<TextProps, DefaultTheme>) {
  switch (textTransform) {
    case "uppercase":
      return css`
          text-transform: uppercase;
        `;
    case "lowercase":
      return css`
          text-transform: lowercase;
        `;

    case "capitalise":
      return css`
          text-transform: capitalize;
        `;
    case "sentence":
      return css`
          text-transform: lowercase;
          &:first-letter {
            text-transform: capitalize;
          }
        `;

    default:
      return css`
          text-transform: none;
        `;
  }
}