import {
  Ascending,
  Descending
} from "grommet-icons";
import React from "react";
import styled from "styled-components";

import { Text } from "src/components/text/Text";

interface BaselineComparisonProps {
  value: number ;
  baselineValue: number;
  type: "percentage" | "count";
}

export const BaselineComparison: React.FC<BaselineComparisonProps> = ({
  value,
  baselineValue,
  type
}) => {
  const difference = value - baselineValue;
  const formatted = type === "percentage" ? `${Math.round(difference * 10) / 10}%` : difference;

  return (
    <Wrapper>
      <ValueText $negative={difference < 0}>
      {difference > 0 && "+"}{formatted}
      </ValueText>
      {difference < 0 ? (
        <Descending color="error" />
      ) : (
        <Ascending color="brand" />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
`;

const ValueText = styled(Text).attrs({ as: "small" })<{ $negative: boolean }>`
  color: ${({ theme, $negative }) => $negative ? theme.colors.error : theme.colors.brand}
`;