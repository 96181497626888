import React from "react";
import {
  Box,
  Card,
  CardBody,
  CheckBox,
  Form,
  Spinner
  // Text
} from "grommet";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { Text } from "src/components/text/Text";
import { TextInput } from "src/components/form/TextInput";
import { Button } from "src/components/button/Button";

import {
  checkIsAdmin,
  CreateUpdateFormValues
} from "../../utils/user-helper";
import { User } from "../../models/User";

const formSchema = Joi.object({
  name: Joi.string().required(),
  email: Joi.string().email({ tlds: false }).required(),
  isAdmin: Joi.boolean()
});

interface FormInputs {
  name: string;
  email: string;
  isAdmin: boolean;
}

interface CreateUpdateUserProps {
  user: User | null;
  createUpdateUser: (formValues: CreateUpdateFormValues) => void;
  onCancel: () => void;
  pendingResponse: boolean
}

export const CreateUpdateUser: React.FC<CreateUpdateUserProps> = ({
  user, createUpdateUser, onCancel, pendingResponse
}) => {
  const defaultIsAdmin = user ? checkIsAdmin(user) : false;

  const {
    register, handleSubmit, formState: { errors }
  } = useForm({ resolver: joiResolver(formSchema) });

  const onSubmit = (data: FormInputs) => createUpdateUser(data);

  return (
      <Card width="medium" pad="medium">
        <Text as="h2" bold>
          {user ? "Update User" : "Create New User"}
        </Text>
        <CardBody pad={{ top: "medium" }}>
          <Form onSubmit={handleSubmit(onSubmit)} validate="submit">
            <Box gap="small">
              <TextInput
                id="newuser-name"
                placeholder="Name"
                defaultValue={user?.name}
                {...register("name", { required: true })}
              />
              <Text color="error">
                {errors.name?.message}
              </Text>

              <TextInput
                id="newuser-email"
                placeholder="Email"
                defaultValue={user?.email}
                {...register("email", { required: true })}
              />
              <Text color="error">
                {errors.email?.message}
              </Text>

              <CheckBox
                id="newuser-isadmin"
                label="Admin?"
                defaultChecked={defaultIsAdmin}
                {...register("isAdmin", { required: true })}
              />
              <Text color="error">
                {errors.isAdmin?.message}
              </Text>
            </Box>
            <Box
              direction="row"
              justify="center"
              gap="medium"
              pad={{ top: "medium" }}
            >
              <Button
                primary
                type="submit"
                disabled={pendingResponse}
                label={pendingResponse ? (
                  <Spinner size="small" color="white"/>
                ) : (
                  user ? "Update" : "Create"
                )}
              />
              <Button label="Cancel" onClick={() => onCancel()} />
            </Box>
          </Form>
        </CardBody>
      </Card>
  );
};