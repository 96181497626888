import React from "react";
import {
  Redirect,
  Route,
  RouteProps
} from "react-router-dom";
import {
  Box,
  Spinner
} from "grommet";

import { useAuthContext } from "src/hooks/useAuthContext";

export const PrivateRoute: React.FC<RouteProps> = ({
  location, children, ...props
}) => {
  const { authDataState } = useAuthContext();

  const ScreenOrRedirect = React.useMemo(() => (authDataState.authenticating ?
      <Box align="center" fill="vertical">
        <Spinner size="large" />
      </Box> :
    authDataState.authenticated ? children : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
    )
  ), [ authDataState, location ]);

  return (
    <Route {...props}>
      {ScreenOrRedirect}
    </Route>
  );
};