import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

import { User } from "../models/User";

export interface CreateUpdateFormValues {
  name?: string;
  email?: string;
  isAdmin?: boolean;
}

export const checkIsAdmin = (user: User): boolean => user.roles.filter(role => role.name === "admin").length > 0;

export const getCurrentUser = async (): Promise<any> => {
  const userInfo = await Auth.currentUserInfo();

  return userInfo;
};

export const isAdmin = (user: CognitoUser | null): boolean => {
  const payload = user?.getSignInUserSession()?.getAccessToken().decodePayload();

  return payload ? payload[ "cognito:groups" ].includes("Admin") : false;
};