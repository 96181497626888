import React from "react";
import Mapbox from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { MapboxContainer } from "./useMapboxContainer";

Mapbox.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "";

const DEFAULT_OPTIONS: Partial<Mapbox.MapboxOptions> = {
  style: "mapbox://styles/mapbox/streets-v11",
  // Centre of England, so the map initialises somewhere sensible
  center: [ -1.1743197, 52.3555177 ],
  zoom: 6
};

export interface UseMapboxOptions extends Omit<Mapbox.MapboxOptions, "container"> {
  container: MapboxContainer;
  shouldLoad?: boolean;
}

type UseMapbox = (options: UseMapboxOptions) => [ Mapbox.Map | undefined, boolean ];

export interface MapEvent extends Omit<Event, "target"> {
  target: Mapbox.Map;
}

export const useMapbox: UseMapbox = ({
  container,
  shouldLoad = true,
  ...options
}) => {
  const [ map, setMap ] = React.useState<Mapbox.Map>();
  const [ loaded, setLoaded ] = React.useState(false);

  React.useEffect(() => {
    if (!container.ready || !container.el || !shouldLoad) {
      return;
    }

    const map = new Mapbox.Map({
      ...DEFAULT_OPTIONS,
      container: container.el,
      ...options
    });

    setMap(map);

    const updateLoadState = () => {
      setLoaded(true);
    };

    map.on("load", updateLoadState);

    return () => {
      map.off("load", updateLoadState);
    };
  }, [ container.ready, shouldLoad ]);

  return [ map, loaded ];
};