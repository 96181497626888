import React, { ReactElement } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody
} from "grommet";
import { Trash } from "grommet-icons";
import styled from "styled-components";

import { Text } from "src/components/text/Text";
import { colours } from "src/theme/copper-theme";

interface ImageCardProps {
  /**
   * Card header contents
   */
  headerText: string;
  onClick?: () => void;
  children?: ReactElement | [ReactElement];
  canDelete?: boolean;
  onDelete?: () => void;
  articlePolymorphic?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const ImageCard = ({
  headerText,
  canDelete,
  onDelete,
  onClick,
  children,
  articlePolymorphic
}: ImageCardProps): ReactElement => {
  const deleteHandler = (e: React.MouseEvent<HTMLElement>) => {
    // Need to stop propagation of card click event
    e.stopPropagation();
    onDelete && onDelete();
  };

  const onKeyDownDeleteHandler = (e: React.KeyboardEvent) => {
    // Need to stop propagation of card click event
    e.stopPropagation();

    // Keypresses other then Enter and Space should not trigger a command
    if (e.key === "Enter" || e.key === " ") {
      onDelete && onDelete();
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    e.stopPropagation();

    // Keypresses other then Enter and Space should not trigger a command
    if (e.key === "Enter" || e.key === " ") {
      onClick && onClick();
    }
  };

  return (
    <CardContainer
      tabIndex={0}
      onKeyDown={onKeyDownHandler}
      as={articlePolymorphic ? "article" : undefined}
      width="large"
      onClick={onClick}
    >
      <TextHeader>
        <Text as="h2">{headerText}</Text>
      </TextHeader>
      <CardBody
        style={{ position: "relative" }}>
        {children}
        {canDelete && <DeleteButton
            icon={<Trash color="black" size="small"/>}
            hoverIndicator={{
              color: "white",
              size: "cover"
            }}
            onClick={deleteHandler}
            onKeyDown={onKeyDownDeleteHandler}
          />
        }
      </CardBody>
    </CardContainer>
  );
};

const DeleteButton = styled(Button)`
  display: flex;
  border-radius: 3.125rem;
  position: absolute;
  background-color: white;
  opacity: 0.75;
  align-self: flex-end;
  margin: 0.25rem;
  &:hover {
    opacity: 1;
    background-color: ${colours.deleteRed};

    svg {
      stroke: white;
    }
  }
`;

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: #F8F8F8;

  transition: all 0.25s ease;

  &:hover {
    cursor: pointer;
    box-shadow:  0.8rem  0.8rem 1.6rem #e3e3e3,
                -0.8rem -0.8rem 1.6rem #ffffff;
  }

  header {
    order: 2;
  }

  header + div {
    order: 1;
  }
`;

const TextHeader = styled(CardHeader)`
  padding: 0.375rem 1.5rem;
`;
