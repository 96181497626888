import {
  Box,
  Footer,
  Header
} from "grommet";
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { LinkPrevious } from "grommet-icons";

import { ReactComponent as CopperLogo } from "src/assets/copper-logo.svg";
import { Text } from "src/components/text/Text";

import { Button } from "../button/Button";

interface PageLayoutProps {
  title?: string;
  HeaderRight?: React.ReactElement;
  showBackButton?: boolean;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  title, HeaderRight, showBackButton, children
}) => {
  const history = useHistory();

  return (
    <Box fill>
      <HeaderBar>
        {
          showBackButton ? (
            <BackButton
              onClick={() => history.push("/")}
              icon={<LinkPrevious color="brand" />}
              aria-label="Back"
              label="Back"
            />
          ) : (
            <HeaderLogo
              name="Copper Logo"
              tabIndex={0}
              onClick={() => history.push("/")}
            />
          )
        }
        <Text as="h1">
          {title}
        </Text>
        <HeaderRightWrapper>
          {HeaderRight}
        </HeaderRightWrapper>
      </HeaderBar>
      <PageContent fill>
        {children}
      </PageContent>
      <FooterBar>
        <Text as="small" color="brand" bold>
          © Copper Consultancy {new Date().getFullYear()}
        </Text>
      </FooterBar>
    </Box>
  );
};

const HeaderBar = styled(Header)`
  position: fixed;
  top: 0;
  height: 5.6rem;
  width: 100%;
  z-index: 100;
  padding: 0.5rem;
  background-color: white;
  border-bottom: 0.2rem solid ${({ theme }) => theme.colors.brand};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BackButton = styled(Button)`
  position: absolute;
  left: 1rem;
`;

const HeaderLogo = styled(CopperLogo)`
  position: absolute;
  left: 2.4rem;
  height: 1.6rem;
  margin-top: 0.2rem;
  fill: ${({ theme }) => theme.colors.brand};

  &:hover {
    cursor: pointer;
  }
`;

const HeaderRightWrapper = styled(Box)`
  position: absolute;
  right: 1.2rem;
`;

const PageContent = styled(Box)`
  padding-top: 5.6rem;
  padding-bottom: 3.5rem;
`;

const FooterBar = styled(Footer)`
  position: fixed;
  bottom: 0;
  height: 3.5rem;
  width: 100%;
  z-index: 100;
  padding: 0.5rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1.5px solid ${({ theme }) => theme.colors.brand};
  box-sizing: border-box;
`;