import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Layer
} from "grommet";
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn
} from "@aws-amplify/ui-react";
import styled from "styled-components";

import { Text } from "src/components/text/Text";
import { Button } from "src/components/button/Button";
import { useAuthContext } from "src/hooks/useAuthContext";
import { copper } from "src/theme/copper-theme";
import loginVideo from "src/assets/community.mp4";

import { ReactComponent as CopperLogo } from "../assets/copper-logo.svg";

export const LoginScreen: React.FC<RouteComponentProps> = () => {
  const { authDataState } = useAuthContext();
  const [ showCognitoUI, setShowCognitoUI ] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (authDataState.authenticated) {
      history.push("/dashboards");
    }
  }, [ authDataState ]);

  const formFields = [
    {
      type: "email",
      label: "Email",
      placeholder: "Enter your email",
      inputProps: {
        required: true,
        autocomplete: "username"
      }
    },
    {
      type: "password",
      label: "Password",
      placeholder: "Enter your password",
      inputProps: {
        required: true,
        autocomplete: "password"
      }
    }
  ];

  return (
    <Box fill>
      <StyledVideo id="background-video" src={loginVideo} height="100%" autoPlay muted loop />
      <Layer background="transparent">
        {showCognitoUI ? (
          <AmplifyAuthContainer>
            <StyledAuthenticator usernameAlias="email">
              <AmplifySignIn slot="sign-in" formFields={formFields} hideSignUp={true} />
            </StyledAuthenticator>
          </AmplifyAuthContainer>
        ) : (
          <LoginBox pad="large" background="primary">
            <CopperLogo
              name="CopperLogo"
              height={28}
              fill={copper.global.colors.black}
            />
            <TitleText>
              Communify Dashboard
            </TitleText>
            <StyledButton
              size="large"
              label="Log in"
              onClick={() => setShowCognitoUI(true)}
              color="black"
            />
            <CopyrightText>
              © Copper Consultancy
            </CopyrightText>
          </LoginBox>
        )}
      </Layer>
    </Box>
  );
};

const StyledVideo = styled.video`
  object-fit: cover;
`;

const LoginBox = styled(Box)`
  text-align: center;
  border-radius: 1rem;
`;

const TitleText = styled(Text).attrs({ as: "h1" })`
  font-size: 3.5rem;
  margin-top: 3rem;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black};
  background-color: transparent;
  margin-top: 2rem;
`;

const StyledAuthenticator = styled(AmplifyAuthenticator)`
  --width: 46rem;
`;

const CopyrightText = styled(Text).attrs({ as: "small" })`
  margin-top: 3rem;
  margin-bottom: -3rem;
`;