import React from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import {
  Box,
  Spinner
} from "grommet";
import {
  useHistory,
  useParams
} from "react-router-dom";
import {
  motion,
  AnimatePresence
} from "framer-motion";

import { Text } from "src/components/text/Text";
import { TextInput } from "src/components/form/TextInput";
import { Error as ErrorComponent } from "src/components/error/Error";
import {
  useMapboxContainer,
  useMapbox,
  UseMapboxOptions,
  useMapboxCentralArea,
  useMapboxGeocoder
} from "src/hooks/map";
import { useActiveDashboardContext } from "src/hooks/useActiveDashboardContext";
import { Button } from "src/components/button/Button";
import { PageLayout } from "src/components/layout/PageLayout";
import { useDashboardsApi } from "src/utils/api/dashboards.api";

interface EditDashboardParams {
  id: string;
}

export const EditDashboardScreen: React.FC = () => {
  const { activeDashboard, setActiveDashboard } = useActiveDashboardContext();
  const { id } = useParams<EditDashboardParams>();
  const history = useHistory();
  const container = useMapboxContainer();
  const [ getDashboardResponse, getDashboardRequest ] = useDashboardsApi("GET_DASHBOARD");
  const [ updateDashboardResponse, updateDashboardRequest ] = useDashboardsApi("UPDATE_DASHBOARD");

  // fetch dashboard info if not in context (page refresh)
  React.useEffect(() => {
    if (!activeDashboard) {
      getDashboardRequest({ id });
    }
  }, [ activeDashboard ]);

  React.useEffect(() => {
    if (getDashboardResponse.data) {
      const dashboard = getDashboardResponse.data;

      setActiveDashboard(dashboard);
      setDashboardName(dashboard.name);
      setRadius(dashboard.location.radius);
    }
  }, [ getDashboardResponse.data ]);

  // only initialise map once activeDashboard is set
  const mapConfig = React.useMemo<UseMapboxOptions>(() => (
    activeDashboard ? {
      container,
      shouldLoad: true,
      center: [ activeDashboard.location.longitude, activeDashboard.location.latitude ],
      zoom: 14 - (activeDashboard.location.radius / 2.5)
    } : {
      container,
      shouldLoad: false
    }
  ), [ container, activeDashboard ]);

  const [ map ] = useMapbox(mapConfig);
  const [ dashboardName, setDashboardName ] = React.useState(activeDashboard?.name || "");
  const [ radius, setRadius ] = React.useState(activeDashboard?.location.radius || 5);

  useMapboxCentralArea(map, radius);
  useMapboxGeocoder(map, radius);

  const [ showUpdateModal, setShowUpdateModal ] = React.useState(false);

  const toggleUpdateModalVisibility = () => {
    setShowUpdateModal(visible => {
      // on hide
      if (visible) {
        // clear dashboard name
        setDashboardName(activeDashboard?.name || "");
      }

      return !visible;
    });
  };

  const handleUpdateDashboard = async () => {
    if (!map || !activeDashboard) {
      return;
    }

    const mapCentre = map.getCenter();

    const updatedDashboard = {
      id: activeDashboard.id.toString(10),
      name: dashboardName,
      latitude: mapCentre.lat,
      longitude: mapCentre.lng,
      radius
    };

    updateDashboardRequest(updatedDashboard);
  };

  React.useEffect(() => {
    if (updateDashboardResponse.data) {
      // navigate to updated dashboard
      history.push(`/dashboards/${updateDashboardResponse.data.id}`);
    }
  }, [ updateDashboardResponse.data ]);

  return (
    <PageLayout
      title="Edit Dashboard"
      HeaderRight={(
        <Button
          label="Done"
          primary
          onClick={toggleUpdateModalVisibility}
        />
      )}
      showBackButton
    >
      <Container fill ref={container.ref}>
        {!activeDashboard ? (
          <Box justify="center" align="center" fill="vertical">
            <Spinner size="large" align="center" justify="center"/>
          </Box>
        ) : (
          <>
            <RadiusControlContainer>
              <RadiusControlLabel as="label">
                Search radius: {radius.toFixed(1)} km
              </RadiusControlLabel>
              <Slider
                value={radius}
                onChange={val => setRadius(val)}
                min={0}
                max={10}
                step={0.1}
              />
            </RadiusControlContainer>
            <AnimatePresence>
              {showUpdateModal && (
                <ModalBackground
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={toggleUpdateModalVisibility}
                >
                  <UpdateDashboardModal onClick={e => e.stopPropagation()}>
                    {updateDashboardResponse.error ? (
                      <>
                        <ErrorComponent error={updateDashboardResponse.error} />
                        <Button
                          label="Okay"
                          onClick={() => {
                            setShowUpdateModal(false);
                            updateDashboardResponse.error = null;
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <TitleText>
                          Update Dashboard
                        </TitleText>
                        <TextInput
                          value={dashboardName}
                          placeholder="Dashboard name"
                          onChange={e => setDashboardName(e.target.value)}
                        />
                        <UpdateDashboardButton
                          primary
                          disabled={updateDashboardResponse.pending}
                          label={updateDashboardResponse.pending ? <Spinner size="small" /> : "Save"}
                          onClick={handleUpdateDashboard}
                        />
                      </>
                    )}
                  </UpdateDashboardModal>
                </ModalBackground>
              )}
            </AnimatePresence>
          </>
        )}
      </Container>
    </PageLayout>
  );
};

const Container = styled(Box)`
  position: relative;
  display: flex;
  flex: 1;
`;

const RadiusControlContainer = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-self: center;
  margin: 0.625rem;
  padding: 1rem 2rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.625rem 0.125rem rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
`;

const RadiusControlLabel = styled(Text)`
  text-align: center;
  margin-bottom: 0.5rem;
`;

const ModalBackground = styled(motion.div)`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000044;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UpdateDashboardModal = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem 4rem;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled(Text).attrs({
  as: "h2",
  bold: true
})`
  text-align: center;
  margin-bottom: 3rem;
`;

const UpdateDashboardButton = styled(Button)`
  margin-top: 3rem;
`;