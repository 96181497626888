import mapboxgl from "mapbox-gl";
import React from "react";
import styled from "styled-components";

import { GeoJSONDemographicsFeature } from "src/models/DemographicsData";
import {
  GeoJSONPoliticalFeature,
  PoliticalFeatureProperties
} from "src/models/PoliticsData";
import { getDemographicsAreaMapMetadata } from "src/hooks/map/utils/demographics";
import { MapDataType } from "src/utils/map-data-helper";
import { getPoliticsAreaMapMetadata } from "src/hooks/map/utils/politics";

import {
  isPoliticsFeature,
  Popup
} from "./Popup";

export type SetSelectedArea = (area: PoliticalFeatureProperties | null) => void;

interface PopupsContainerProps {
  map: mapboxgl.Map | undefined;
  mapLoaded: boolean;
  active: boolean;
  type: MapDataType;
  councils?: GeoJSONDemographicsFeature[];
  wards?: GeoJSONDemographicsFeature[] | GeoJSONPoliticalFeature[];
  setSelectedCouncil?: SetSelectedArea;
  setSelectedWard?: SetSelectedArea;
}

export const PopupsContainer: React.FC<PopupsContainerProps> = ({
  map,
  mapLoaded,
  active,
  type,
  councils,
  wards,
  setSelectedCouncil,
  setSelectedWard
}) => {
  const [ councilPopups, setCouncilPopups ] = React.useState<mapboxgl.Popup[]>([]);
  const [ councilPopupsInitialised, setCouncilPopupsInitialised ] = React.useState(false);

  const registerCouncilPopup = (popup: mapboxgl.Popup) => {
    if (!councilPopupsInitialised) {
      setCouncilPopups(popups => [ ...popups, popup ]);
    }
  };

  const [ wardPopups, setWardPopups ] = React.useState<mapboxgl.Popup[]>([]);
  const [ wardPopupsInitialised, setWardPopupsInitialised ] = React.useState(false);

  const registerWardPopup = (popup: mapboxgl.Popup) => {
    if (!wardPopupsInitialised) {
      setWardPopups(popups => [ ...popups, popup ]);
    }
  };

  // hide popups when tab loses focus
  React.useEffect(() => {
    if (!active) {
      councilPopups.forEach(popup => popup.remove());
      wardPopups.forEach(popup => popup.remove());
    }
  }, [ active ]);

  const CouncilPopups = React.useMemo(() => {
    const popups = councils?.map(feature => {
      const { fillId } = getDemographicsAreaMapMetadata(feature.properties);

      return (
        <Popup
          key={fillId}
          type={type}
          map={map}
          mapLoaded={mapLoaded}
          parentLayerId={fillId}
          register={registerCouncilPopup}
          council={feature.properties}
          setSelectedArea={setSelectedCouncil}
        />
      );
    });

    if (popups) {
      setCouncilPopupsInitialised(true);
    }

    return popups;
  }, [
    map,
    mapLoaded,
    councils
  ]);

  const WardPopups = React.useMemo(() => {
    const popups = wards?.map(feature => {
      const { fillId } = isPoliticsFeature(feature.properties) ?
        getPoliticsAreaMapMetadata(feature.properties) :
        getDemographicsAreaMapMetadata(feature.properties);

      return (
        <Popup
          key={fillId}
          type={type}
          map={map}
          mapLoaded={mapLoaded}
          parentLayerId={fillId}
          register={registerWardPopup}
          ward={feature.properties}
          setSelectedArea={setSelectedWard}
        />
      );
    });

    if (wards) {
      setWardPopupsInitialised(true);
    }

    return popups;
  }, [
    map,
    mapLoaded,
    wards
  ]);

  return (
    <HiddenWrapper>
      {CouncilPopups}
      {WardPopups}
    </HiddenWrapper>
  );
};

// can't use `display: none` as need to draw onto dom
// otherwise nothing is inserted into popup box
const HiddenWrapper = styled.div`
  position: absolute;
  visibility: hidden;
`;
