import React from "react";
import {
  Box,
  Spinner
} from "grommet";
import styled from "styled-components";
import Slider from "rc-slider";
import {
  motion,
  AnimatePresence
} from "framer-motion";
import { useHistory } from "react-router-dom";

import "rc-slider/assets/index.css";

import { Text } from "src/components/text/Text";
import { TextInput } from "src/components/form/TextInput";
import {
  useMapboxContainer,
  useMapbox,
  useMapboxCentralArea,
  useMapboxGeocoder
} from "src/hooks/map";
import { Button } from "src/components/button/Button";
import { Error as ErrorComponent } from "src/components/error/Error";
import { PageLayout } from "src/components/layout/PageLayout";
import { useDashboardsApi } from "src/utils/api/dashboards.api";

export const NewDashboardScreen: React.FC = () => {
  const container = useMapboxContainer();
  const [ map ] = useMapbox({ container });
  const [ dashboardName, setDashboardName ] = React.useState("");
  const [ radius, setRadius ] = React.useState(5);

  useMapboxCentralArea(map, radius);
  useMapboxGeocoder(map, radius);

  const [ showCreateModal, setShowCreateModal ] = React.useState(false);
  const [ createDashboardResponse, createDashboardRequest ] = useDashboardsApi("CREATE_DASHBOARD");
  const history = useHistory();

  const toggleCreateModalVisibility = () => {
    setShowCreateModal(visible => {
      // on hide
      if (visible) {
        // clear dashboard name
        setDashboardName("");
      }

      return !visible;
    });
  };

  const handleCreateDashboard = async () => {
    if (!map) {
      return;
    }
    const mapCentre = map.getCenter();

    const newDashboard = {
      name: dashboardName,
      latitude: mapCentre.lat,
      longitude: mapCentre.lng,
      radius
    };

    createDashboardRequest(newDashboard);
  };

  React.useEffect(() => {
    if (createDashboardResponse.data) {
      // navigate to newly created dashboard
      history.push(`/dashboards/${createDashboardResponse.data.id}`);
    }
  }, [ createDashboardResponse.data ]);

  return (
    <PageLayout
      title="New Dashboard"
      HeaderRight={(
        <Button
          label="Done"
          primary
          onClick={toggleCreateModalVisibility}
        />
      )}
      showBackButton
    >
      <Container fill ref={container.ref}>
        <RadiusControlContainer>
          <RadiusControlLabel title={`Search radius: ${radius.toFixed(1)} km`}>
            Search radius: {radius.toFixed(1)} km
          </RadiusControlLabel>
          <Slider
            value={radius}
            onChange={val => setRadius(val)}
            min={0}
            max={10}
            step={0.1}
          />
        </RadiusControlContainer>
        <AnimatePresence>
          {showCreateModal && (
            <ModalBackground
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={toggleCreateModalVisibility}
            >
              <CreateDashboardModal onClick={e => e.stopPropagation()}>
                {createDashboardResponse.error ? (
                  <>
                    <ErrorComponent error={createDashboardResponse.error} />
                    <Button
                      label="Okay"
                      onClick={() => {
                        setShowCreateModal(false);
                        createDashboardResponse.error = null;
                      }}
                    />
                  </>
                ) : (
                  <>
                    <TitleText>
                      Create Dashboard
                    </TitleText>
                    <TextInput
                      value={dashboardName}
                      placeholder="Dashboard name"
                      onChange={e => setDashboardName(e.target.value)}
                    />
                    <CreateDashboardButton
                      primary
                      disabled={createDashboardResponse.pending}
                      label={createDashboardResponse.pending ? <Spinner size="small"/> : "Save"}
                      onClick={handleCreateDashboard}
                    />
                  </>
                )}
              </CreateDashboardModal>
            </ModalBackground>
          )}
        </AnimatePresence>
      </Container>
    </PageLayout>
  );
};

const Container = styled(Box)`
  position: relative;
  display: flex;
  flex: 1;
`;

const RadiusControlContainer = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-self: center;
  margin: 0.625rem;
  padding: 1rem 2rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.625rem 0.125rem rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
`;

const RadiusControlLabel = styled(Text).attrs({ as: "label" })`
  text-align: center;
  margin-bottom: 0.5rem;
`;

const ModalBackground = styled(motion.div)`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000044;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CreateDashboardModal = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem 4rem;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled(Text).attrs({
  as: "h2",
  bold: true
})`
  text-align: center;
  margin-bottom: 3rem;
`;

const CreateDashboardButton = styled(Button)`
  margin-top: 3rem;
`;