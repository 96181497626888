import React from "react";
import mapboxgl from "mapbox-gl";

import { PoliticsData } from "src/models/PoliticsData";

import {
  addPoliticsAreaToMap,
  hidePoliticsArea
} from "./utils/politics";

type UsePoliticsMapFeatures = (
  map: mapboxgl.Map | undefined,
  mapLoaded: boolean,
  isActiveTab: boolean,
  politicsData: PoliticsData | null
) => void;

export const usePoliticsMapFeatures: UsePoliticsMapFeatures = (
  map,
  mapLoaded,
  isActiveTab,
  politicsData
) => {
  // initialise council and ward areas on load
  React.useEffect(() => {
    if (map && mapLoaded && politicsData) {
      // on tab focus
      if (isActiveTab) {
        // create / show existing ward features
        politicsData.features.map(feature => addPoliticsAreaToMap(map, feature));

      // when tab loses focus
      } else {
        // hide ward features
        politicsData.features.forEach(feature => hidePoliticsArea(map, feature.properties));
      }
    }
  }, [
    map,
    mapLoaded,
    politicsData,
    isActiveTab
  ]);
};