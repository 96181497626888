import mapboxgl from "mapbox-gl";

import {
  addMapLayer,
  hideMapLayer
} from "src/utils/map-data-helper";

import { buildAnyFilters } from "./greenspace";

export const healthcareFiltersByCategory: Record<string, string[]> = {
  healthcare_hospitals: [ "Hospital" ],
  healthcare_medicalCareAccommodation: [ "Medical Care Accommodation", "Hospice" ]
};

const buildHealthcareLayer = (id: string): mapboxgl.AnyLayer => ({
  id,
  type: "fill",
  source: "healthcare",
  paint: {
    "fill-color": "red",
    "fill-opacity": 0.5
  },
  filter: buildAnyFilters("SiteFunction", healthcareFiltersByCategory[ id ])
});

export const addHealthcareLayers = (map: mapboxgl.Map): void => {
  for (const categoryId in healthcareFiltersByCategory) {
    addMapLayer(map, buildHealthcareLayer(categoryId));
  }
};

export const hideHealthcareLayers = (map: mapboxgl.Map): void => {
  const layerIds = Object.keys(healthcareFiltersByCategory);

  layerIds.forEach(layerId => hideMapLayer(map, layerId));
};