import { Heading } from "grommet";
import {
  Ascending,
  Descending
} from "grommet-icons";
import React from "react";

import { Card } from "src/components/card/Card";
import { useActiveDashboardSlug } from "src/hooks/useActiveDashboardSlug";
import { DataPoint } from "src/models/DataPoint";
import { getBaselineData } from "src/utils/chart-data-helper";

import onsLogo from "../../assets/ons-logo.png";

interface ClaimantCountProps {
  data: DataPoint[];
  withBaseline: boolean;
}

export const ClaimantCount: React.FC<ClaimantCountProps> = ({ data, withBaseline }) => {
  const dashboardSlug = useActiveDashboardSlug();
  const baselineData = React.useMemo(() => withBaseline ? getBaselineData("claimantCount") : null, [ withBaseline ]);

  const comparisonArrow = (dataPointValue: number, dataPointLabel: string | number, small = false) => {
    if (!baselineData) {
      return undefined;
    }

    const baselineDataPoint = baselineData.filter(value => value.label === dataPointLabel);

    if (dataPointValue > baselineDataPoint[ 0 ].value) {
      return small ? "↑" : <Ascending color="brand" size="large" />;
    }

    if (dataPointValue < baselineDataPoint[ 0 ].value) {
      return small ? "↓" : <Descending color="error" size="large" />;
    }
  };

  return (
    <Card
      headerText="Claimant Count"
      dataSource={{
        logo: onsLogo,
        name: "Claimant Count (Latest)",
        link: "https://www.nomisweb.co.uk/query/construct/summary.asp?reset=yes&mode=construct&dataset=162&version=0&anal=1&initsel="
      }}
      fill="horizontal"
      fileName={`claimant-count-${dashboardSlug}`}
    >
      <Heading>
        {data ? (
          <>
            {data[ 0 ].value}% {comparisonArrow(data[ 0 ].value, data[ 0 ].label)}
          </>
        ) : (
          "Data unavailable"
        )}
      </Heading>
    </Card>
  );
};