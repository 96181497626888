import mapboxgl from "mapbox-gl";

import {
  addMapLayer,
  hideMapLayer
} from "src/utils/map-data-helper";

export type Filter = Array<string | string[]>;

const buildAnyFilter = (type: string, term: string): Filter => [
  "==",
  [ "get", type ],
  term
];

export const buildAnyFilters = (type: string, terms: string[]): Array<string | Filter> => {
  if (terms.length === 1) {
    return buildAnyFilter("Type", terms[ 0 ]);
  } else {
    return [ "any", ...terms.map(term => buildAnyFilter("Type", term)) ];
  }
};

export const greenspaceFiltersByCategory: Record<string, string[]> = {
  greenspace_parksAndGardens: [ "Public Park Or Garden", "Playing Field" ],
  greenspace_playAreas: [ "Play Space" ],
  greenspace_growingSpaces: [ "Allotments Or Community Growing Spaces" ],
  greenspace_sportsFacilities: [
    "Other Sports Facility",
    "Tennis Court",
    "Bowling Green"
  ],
  greenspace_religiousGrounds: [ "Religious Grounds", "Cemetery" ]
};

const buildGreenspaceLayer = (id: string): mapboxgl.AnyLayer => ({
  id,
  type: "fill",
  source: "greenspace",
  paint: {
    "fill-color": "green",
    "fill-opacity": 0.5
  },
  filter: buildAnyFilters("Type", greenspaceFiltersByCategory[ id ])
});

export const addGreenspaceLayers = (map: mapboxgl.Map): void => {
  for (const categoryId in greenspaceFiltersByCategory) {
    addMapLayer(map, buildGreenspaceLayer(categoryId));
  }
};

export const hideGreenspaceLayers = (map: mapboxgl.Map): void => {
  const layerIds = Object.keys(greenspaceFiltersByCategory);

  layerIds.forEach(layerId => hideMapLayer(map, layerId));
};