import React, { useState } from "react";

import { Dashboard } from "src/models/Dashboard";

export interface ActiveDashboardContextValue {
  activeDashboard: Dashboard | null;
  setActiveDashboard: (newDashboard: Dashboard | null) => void;
}

export const ActiveDashboardContext = React.createContext<ActiveDashboardContextValue>({
  activeDashboard: null,
  setActiveDashboard: () => null
});

export const ActiveDashboardProvider: React.FC = ({ children }) => {
  const [ activeDashboard, setActiveDashboard ] = useState<Dashboard | null>(null);

  const contextValue: ActiveDashboardContextValue = React.useMemo(() => ({
    activeDashboard,
    setActiveDashboard
  }), [ activeDashboard ]);

  return (
    <ActiveDashboardContext.Provider value={contextValue}>
      {children}
    </ActiveDashboardContext.Provider>
  );
};