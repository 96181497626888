import React from "react";
import {
  Chart,
  ChartType,
  TooltipModel,
  TooltipOptions
} from "chart.js";

interface ExternalTooltipEvent<T extends ChartType> {
  chart: Chart;
  tooltip: TooltipModel<T>;
}

type ExternalTooltipEventHandler<T extends ChartType> = (event: ExternalTooltipEvent<T>) => void;

type UseTooltip = <T extends ChartType>() => [ TooltipModel<T> | null, Partial<TooltipOptions<T>> ];

export const useChartTooltip: UseTooltip = <T extends ChartType>() => {
  const [ tooltipData, setTooltipData ] = React.useState<TooltipModel<T> | null>(null);

  const handleExternalTooltipEvent = React.useCallback<ExternalTooltipEventHandler<T>>(({ tooltip }) => {
    // mouse over event
    if (tooltip.opacity === 1) {
      // spread to force React to treat as new object
      setTooltipData({ ...tooltip });
    // mouse out event
    } else {
      setTooltipData(null);
    }
  }, []);

  const tooltipConfig = React.useMemo<Partial<TooltipOptions<T>>>(() => ({
    enabled: false,
    external: handleExternalTooltipEvent
  }), []);

  return [ tooltipData, tooltipConfig ];
};