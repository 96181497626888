import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CheckBox,
  CardFooter,
  Anchor,
  Image
} from "grommet";

import { LayerVisibility } from "src/utils/map-data-helper";

import { GeoJSONGeographyFeature } from "../../models/GeographyData";

interface CheckboxOptions {
  [key: string]: string
}

interface CheckboxCardProps {
  section: string;
  headerText: string;
  options?: GeoJSONGeographyFeature[];
  checkboxOptions: CheckboxOptions;
  dataSource: {
    logo?: string;
    name: string;
    link: string;
  }
  onCheckboxClick: (featureToUpdate: string, visibility: LayerVisibility) => void;
}

export const CheckboxCard: React.FC<CheckboxCardProps> = ({
  section,
  headerText,
  checkboxOptions,
  dataSource,
  onCheckboxClick
}) => {
  const [ headerChecked, setHeaderChecked ] = useState(true);

  const handleHeaderCheck = () => {
    onCheckboxClick(section, !headerChecked ? LayerVisibility.Visible : LayerVisibility.Hidden);
    setHeaderChecked(!headerChecked);
  };

  const handleChildClick = (key: string, checked: boolean) => {
    onCheckboxClick(key, checked ? LayerVisibility.Visible : LayerVisibility.Hidden);
  };

  return (
    <Card fill="horizontal">
      <CardHeader pad="medium">
        <CheckBox label={headerText} checked={headerChecked} onClick={handleHeaderCheck}/>
      </CardHeader>
      <CardBody pad={{
        horizontal: "large",
        vertical: "medium"
      }}>
        {
          Object.keys(checkboxOptions).map(key => (
            <CheckBox
              key={key}
              label={checkboxOptions[ key ]}
              disabled={headerChecked}
              pad="xsmall"
              onChange={event => handleChildClick(key, event.target.checked)}
            />
          ))
        }
      </CardBody>
      <CardFooter pad="small">
        <Anchor
          size="small"
          href={dataSource.link}
          label={dataSource.name}
          icon={<Image height="30" src={dataSource.logo}/>}
          target="_blank"
          aria-label={`Link to dataset ${dataSource.name} (opens in new tab)`}
        />
      </CardFooter>
      </Card>
  );
};