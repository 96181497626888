import mapboxgl, { GeoJSONSourceOptions } from "mapbox-gl";

import {
  ElectionResult,
  PartyName
} from "src/models/PoliticsData";
import { politicalPartyColours } from "src/theme/copper-theme";
import { DashboardLocation } from "src/models/Dashboard";

export enum MapDataType {
  Demographics,
  Geography,
  Politics
}

export enum LayerVisibility {
  Visible = "visible",
  Hidden = "none"
}

// Metres per pixel at latitude +- 60 per zoom level (0 to 22)
export const metresPerPixel = [
  39135.742,
  19567.871,
  9783.936,
  4891.968,
  2445.984,
  1222.992,
  611.496,
  305.748,
  152.874,
  76.437,
  38.218,
  19.109,
  9.555,
  4.777,
  2.389,
  1.194,
  0.597,
  0.299,
  0.149,
  0.075,
  0.037,
  0.019,
  0.009
];

export const calculateZoomLevel = (location?: DashboardLocation): number => {
  // Check that location is set
  if (location) {
    let zoomLevel = 0;
    const screenWidth = screen.width;
    const mapWidth = screenWidth > 768 ? screenWidth / 2 : screenWidth;
    // 1.5 times the circle diameter, in metres
    const circleDiameterWithPadding = location.radius * 1000 * 3;

    // Only loop through the available zoom levels
    while (zoomLevel < 23) {
      const totalMetresVisible = metresPerPixel[ zoomLevel ] * mapWidth;

      if (totalMetresVisible < circleDiameterWithPadding) {
        return zoomLevel - 1;
      }

      zoomLevel++;
    }

    return 11;
  } else {
    return 11;
  }
};

export const getElectedPartyNames = (latestElectionResults: ElectionResult[]): string[] => (
  (latestElectionResults || [])
    // Get results where candidate was elected
    .filter(individualResult => individualResult.elected)
    // Return just the strings of the party names
    .map(result => result.party.name)
);

const stringMode = (strings: string[]): string | null => {
  const counts: Record<string, number> = {};
  let maxCount = 0;
  let mode: string | null = null;

  for (const string of strings) {
    const currentCount = counts[ string ] || 0;
    const newCount = currentCount + 1;

    counts[ string ] = newCount;

    if (newCount > maxCount) {
      maxCount = newCount;
      mode = string;
    }
  }

  return mode;
};

export const getPartyColour = (partyNames: string[]): string => {
  // default colour for ward with no elected parties
  if (partyNames.length === 0) {
    return "#606060";
  }

  const majorityParty = stringMode(partyNames);

  return majorityParty && politicalPartyColours[ majorityParty ] ?
    politicalPartyColours[ majorityParty ] :
    politicalPartyColours[ PartyName.Independent ];
};

export const addMapDataSource = (map: mapboxgl.Map, id: string, data: GeoJSONSourceOptions["data"]): void => {
  if (!map.getSource(id)) {
    map.addSource(id, {
      type: "geojson",
      data
    });
  }
};

export const showMapLayer = (map: mapboxgl.Map, id: string): void => {
  if (map.getLayer(id)) {
    map.setLayoutProperty(id, "visibility", "visible");
  }
};

export const hideMapLayer = (map: mapboxgl.Map, id: string): void => {
  if (map.getLayer(id)) {
    map.setLayoutProperty(id, "visibility", "none");
  }
};

const isFillLayer = (layer: mapboxgl.AnyLayer): layer is mapboxgl.FillLayer => (layer as mapboxgl.FillLayer).type === "fill";
const isLineLayer = (layer: mapboxgl.AnyLayer): layer is mapboxgl.LineLayer => (layer as mapboxgl.LineLayer).type === "line";

export const addMapLayer = (map: mapboxgl.Map, layer: mapboxgl.AnyLayer): void => {
  if (!map.getLayer(layer.id)) {
    map.addLayer(layer);
  } else if (
    (isFillLayer(layer) || isLineLayer(layer)) &&
    layer.layout?.visibility === "none"
  ) {
    return;
  } else {
    showMapLayer(map, layer.id);
  }
};