import React from "react";
import styled from "styled-components";

import { Text } from "src/components/text/Text";
import { ElectionResult as ElectionResultType } from "src/models/PoliticsData";
import { getPartyColour } from "src/utils/map-data-helper";

interface ElectionResultProps {
  result: ElectionResultType;
  totalVotes: number;
}

export const ElectionResult: React.FC<ElectionResultProps> = ({ result, totalVotes }) => {
  const fillColor = getPartyColour([ result.party.name ]);

  return (
    <Wrapper>
      <PartyBadge $color={fillColor} />
      <Result>
        <Text as="small" style={{ marginRight: "4rem" }}>
          {result.person.name}
        </Text>
        <Text as="small">
          {result.num_ballots} ({Math.round((result.num_ballots / totalVotes) * 100)}%)
        </Text>
      </Result>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem 0;
`;

const PartyBadge = styled.div<{ $color: string }>`
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 0.6rem;
  margin-right: 1rem;
  background-color: ${({ $color }) => $color};
`;

const Result = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;