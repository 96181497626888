import React from "react";
import mapboxgl from "mapbox-gl";

import { DemographicsData } from "src/models/DemographicsData";
import { PoliticalFeatureProperties } from "src/models/PoliticsData";

import {
  addDemographicsAreaToMap,
  hideDemographicsArea,
  showDemographicsArea
} from "./utils/demographics";

export const useDemographicsMapFeatures = (
  map: mapboxgl.Map | undefined,
  mapLoaded: boolean,
  isActiveTab: boolean,
  demographicsData: DemographicsData | null,
  selectedCouncil: PoliticalFeatureProperties | null
): void => {
  // initialise council and ward areas on load
  React.useEffect(() => {
    if (map && mapLoaded && demographicsData) {
      const { councils, wards } = demographicsData;

      // when tab is focused
      if (isActiveTab) {
        // create / show existing council features
        councils.features.map(feature => addDemographicsAreaToMap(map, feature));
        // create / show existing ward features
        wards.features.map(feature => addDemographicsAreaToMap(map, feature));
      // when tab loses focus
      } else {
        // hide all councils
        councils.features.forEach(feature => hideDemographicsArea(map, feature.properties));
        // hide all wards
        wards.features.forEach(feature => hideDemographicsArea(map, feature.properties));
      }
    }
  }, [
    map,
    mapLoaded,
    demographicsData,
    isActiveTab
  ]);

  // on selected council change
  React.useEffect(() => {
    if (map && mapLoaded && demographicsData && isActiveTab) {
      demographicsData.councils.features.forEach(feature => {
        // hide selected council on map
        if (feature.properties.id === selectedCouncil?.id) {
          hideDemographicsArea(map, feature.properties);
        // show all other councils
        } else {
          showDemographicsArea(map, feature.properties);
        }
      });

      demographicsData.wards.features.forEach(feature => {
        // show wards belonging to selected council
        if (feature.properties.parent_area === selectedCouncil?.id) {
          showDemographicsArea(map, feature.properties);
        // hide all others
        } else {
          hideDemographicsArea(map, feature.properties);
        }
      });
    }
  }, [
    map,
    mapLoaded,
    demographicsData,
    isActiveTab,
    selectedCouncil
  ]);
};