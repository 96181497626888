import { useAxios } from "src/hooks/useAxios";
import { User } from "src/models/User";

import {
  EndpointConfig,
  RequestConfig,
  UseApiRequest
} from "./types";

// request + response types

type NoParams = Record<string, never>;

interface UserPathParams {
  id: string;
}

interface CreateUserParams {
  name: string;
  email: string;
  isAdmin: boolean;
}

type UpdateUserParams = UserPathParams & CreateUserParams;

type UsersEndpointConfig = {
  GET_USER: EndpointConfig<
    UserPathParams,
    User
  >;
  GET_USERS: EndpointConfig<
    NoParams,
    User[]
  >;
  CREATE_USER: EndpointConfig<
    CreateUserParams,
    User
  >;
  UPDATE_USER: EndpointConfig<
    UpdateUserParams,
    User
  >;
  DELETE_USER: EndpointConfig<
    UserPathParams,
    null
  >;
};

// users api specific logic

const requestInitialisers: RequestConfig<UsersEndpointConfig> = {
  GET_USER: (
    ({ id }) => ({
      url: `/users/${id}`,
      method: "GET"
    })
  ),
  GET_USERS: (
    () => ({
      url: "/users",
      method: "GET"
    })
  ),
  CREATE_USER: (
    params => ({
      url: "/users",
      method: "POST",
      data: params
    })
  ),
  UPDATE_USER: (
    ({ id, ...params }) => ({
      url: `/users/${id}`,
      method: "PUT",
      data: params
    })
  ),
  DELETE_USER: (
    ({ id }) => ({
      url: `/users/${id}`,
      method: "DELETE"
    })
  )
};

export const useUsersApi: UseApiRequest<UsersEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
