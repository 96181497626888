import {
  ChartType,
  TooltipModel
} from "chart.js";
import React from "react";
import styled from "styled-components";
import {
  AnimatePresence,
  motion
} from "framer-motion";

import { DataPoint } from "src/models/DataPoint";
import { Text } from "src/components/text/Text";

import { BaselineComparison } from "./BaselineComparison";

interface TooltipProps<T extends ChartType> {
  data: TooltipModel<T> | null;
  baselineData?: DataPoint[] | null;
  dataType?: "percentage" | "count";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TooltipComponent = <T extends ChartType>(props: React.PropsWithChildren<TooltipProps<T>>, context?: any) => React.ReactElement<any, any> | null;

export const Tooltip: TooltipComponent = ({
  data,
  baselineData = null,
  dataType = "percentage"
}) => {
  // store tooltip ref in state to allow useEffect to trigger on ref change
  const [ tooltipRef, setTooltipRef ] = React.useState<HTMLDivElement | null>(null);
  // using callback ref here to update tooltipRef on mount/unmount/change
  const containerRef = React.useCallback((node: HTMLDivElement) => setTooltipRef(node), []);
  // every time the tooltip is added / updated / removed, need to re-measure width to align correctly
  const [ tooltipWidth, setTooltipWidth ] = React.useState(0);

  React.useEffect(() => {
    if (tooltipRef !== null) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry && entry.borderBoxSize.length > 0) {
            setTooltipWidth(entry.borderBoxSize[ 0 ].inlineSize);
          }
        }
      });

      observer.observe(tooltipRef);

      return () => {
        observer.disconnect();
      };
    }
  }, [ tooltipRef ]);

  const tooltipX = React.useMemo(() => {
    if (tooltipWidth) {
      if (data?.xAlign === "left") {
        return data.caretX;
      } else if (data?.xAlign === "center") {
        return data.caretX - (tooltipWidth / 2);
      } else if (data?.xAlign === "right") {
        return data.caretX - tooltipWidth;
      }
    }

    return 0;
  }, [ data, tooltipWidth ]);

  const swatchX = React.useMemo(() => {
    if (tooltipWidth) {
      if (data?.xAlign === "left") {
        return 2;
      } else if (data?.xAlign === "center") {
        return (tooltipWidth / 2) - 6;
      } else if (data?.xAlign === "right") {
        return tooltipWidth - 16;
      }
    }

    return 0;
  }, [ data, tooltipWidth ]);

  const backgroundColor = data?.labelColors[ 0 ].backgroundColor.toString() || "";
  const dataPoint = data?.dataPoints[ 0 ];
  const baselineDataPoint = baselineData ? baselineData[ dataPoint?.dataIndex || 0 ] : null;
  const formattedDataValue = dataType === "percentage" ? `${Math.round((dataPoint?.raw as number) * 10) / 10}%` : dataPoint?.formattedValue;

  return (
    <AnimatePresence>
      {data && (
        <Anchor
          initial={{
            opacity: 0,
            top: data.caretY,
            left: tooltipX
          }}
          animate={{
            opacity: 1,
            top: data.caretY,
            left: tooltipX
          }}
          exit={{ opacity: 0 }}
        >
          <TooltipContainer ref={containerRef}>
            <Swatch
              animate={{
                left: swatchX,
                backgroundColor
              }}
            />
            <LabelText>
              {dataPoint?.label}
            </LabelText>
            <ValueRow>
              <Text as="small">
                {formattedDataValue}
              </Text>
              {baselineDataPoint && (
                <BaselineComparison
                  value={dataPoint?.raw as number}
                  baselineValue={baselineDataPoint.value}
                  type={dataType}
                />
              )}
            </ValueRow>
          </TooltipContainer>
        </Anchor>
      )}
    </AnimatePresence>
  );
};

const Anchor = styled(motion.div)`
  z-index: 1;
  position: absolute;
  pointer-events: none;
`;

const TooltipContainer = styled.div`
  position: relative;
  padding: 1.6rem;
  padding-bottom: 0.8rem;
  border-radius: 0.8rem;
  max-width: 30rem;
  background-color: white;
  border: 0.5px solid ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Swatch = styled(motion.div)`
  position: absolute;
  align-self: center;
  top: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
`;

const LabelText = styled(Text).attrs({
  as: "label",
  bold: true
})`
  margin-bottom: 0.2rem!important;
`;

const ValueRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;