import React from "react";
import styled from "styled-components";
import mapboxgl from "mapbox-gl";

import { Text } from "src/components/text/Text";
import {
  PoliticalFeatureProperties,
  PoliticalWardFeatureProperties
} from "src/models/PoliticsData";
import { MapDataType } from "src/utils/map-data-helper";

import { ElectionResult } from "./ElectionResult";
import { Button } from "../button/Button";

type SetSelectedArea = (area: PoliticalFeatureProperties) => void;

interface PopupProps {
  map: mapboxgl.Map | undefined;
  mapLoaded: boolean;
  parentLayerId: string;
  type: MapDataType;
  register: (popup: mapboxgl.Popup) => void;
  ward?: PoliticalFeatureProperties | PoliticalWardFeatureProperties;
  council?: PoliticalFeatureProperties;
  setSelectedArea?: SetSelectedArea;
}

export const isPoliticsFeature = (ward: PoliticalFeatureProperties | PoliticalWardFeatureProperties): ward is PoliticalWardFeatureProperties => !!(ward as PoliticalWardFeatureProperties).latestElectionId;

// Mapbox's vanilla Popup elements are linked the virtual DOM via `Popup.setDOMContent`
// this ensures that each popups' state and event handlers can be synced with the rest of the app
export const Popup: React.FC<PopupProps> = ({
  map,
  mapLoaded,
  parentLayerId,
  type,
  register,
  ward,
  council,
  setSelectedArea
}) => {
  const [ popupRef, setPopupRef ] = React.useState<HTMLDivElement | null>(null);
  const mapboxPopup = React.useRef<mapboxgl.Popup>();

  React.useEffect(() => {
    if (popupRef && map && mapLoaded) {
      const popup = new mapboxgl.Popup().setMaxWidth("40rem").setDOMContent(popupRef);

      mapboxPopup.current = popup;
      register(popup);

      map.on("click", parentLayerId, e => {
        popup.setLngLat(e.lngLat).addTo(map);
      });

      map.on("mouseenter", parentLayerId, () => {
        map.getCanvas().style.cursor = "pointer";
      });

      map.on("mouseleave", parentLayerId, () => {
        map.getCanvas().style.cursor = "";
      });
    }
  }, [
    map,
    mapLoaded,
    popupRef,
    register
  ]);

  if (type === MapDataType.Demographics) {
    const area = council || ward;

    if (!area) {
      return null;
    }

    const onSetSelectedArea = () => {
      if (setSelectedArea) {
        setSelectedArea({ ...area });
        mapboxPopup.current?.remove();
      }
    };

    return (
      <Container ref={setPopupRef}>
        <AreaNameText>
          {area.name}
        </AreaNameText>
        <AreaTypeText>
          {area.type_name}
        </AreaTypeText>
        <ResultsWrapper>
          {!!setSelectedArea && (
            <Button
              style={{
                marginTop: "1rem",
                width: "100%"
              }}
              primary
              label="View data"
              onClick={onSetSelectedArea}
            />
          )}
        </ResultsWrapper>
      </Container>
    );
  } else {
    if (!ward || !isPoliticsFeature(ward)) {
      return null;
    }

    if (!ward.latestElectionResult) {
      return (
        <Container ref={setPopupRef}>
          <AreaNameText>
            {ward.name}
          </AreaNameText>
          <ResultsWrapper>
            <Text as="small">
              Results unavailable for this area
            </Text>
          </ResultsWrapper>
        </Container>
      );
    }

    const totalVotes = ward.latestElectionResult.reduce((prev, curr) => prev + curr.num_ballots, 0);
    const electedSeats = ward.latestElectionResult.filter(result => result.elected);

    return (
      <Container ref={setPopupRef}>
        <AreaNameText>
          {ward.name}
        </AreaNameText>
        <ResultsWrapper>
          {electedSeats.map(result => (
            <ElectionResult
              key={result.person.id}
              result={result}
              totalVotes={totalVotes}
            />
          ))}
        </ResultsWrapper>
      </Container>
    );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
`;

const AreaNameText = styled(Text).attrs({
  as: "label",
  bold: true
})``;

const AreaTypeText = styled(Text).attrs({ as: "small" })`
  margin-top: 0.5rem;
`;

const ResultsWrapper = styled.div`
  min-width: 25rem;
  max-width: 38rem;
  margin-top: 1rem;
`;