import { useAxios } from "src/hooks/useAxios";
import {
  DemographicsData,
  DemographicsDataSections
} from "src/models/DemographicsData";
import { GeographyData } from "src/models/GeographyData";
import { PoliticsData } from "src/models/PoliticsData";

import {
  EndpointConfig,
  RequestConfig,
  UseApiRequest
} from "./types";

// request + response types

type NoParams = Record<string, never>;

interface GetMetricForDashboardParams {
  id: string;
}

type MetricsEndpointConfig = {
  GET_DEMOGRAPHICS: EndpointConfig<
    GetMetricForDashboardParams,
    DemographicsData
  >;
  GET_DEMOGRAPHICS_BASELINE: EndpointConfig<
    NoParams,
    DemographicsDataSections
  >;
  GET_GEOGRAPHY: EndpointConfig<
    GetMetricForDashboardParams,
    GeographyData
  >;
  GET_POLITICS: EndpointConfig<
    GetMetricForDashboardParams,
    PoliticsData
  >;
};

// metrics api specific logic

const requestInitialisers: RequestConfig<MetricsEndpointConfig> = {
  GET_DEMOGRAPHICS: (
    ({ id }) => ({
      url: `/dashboards/${id}/demographics`,
      method: "GET"
    })
  ),
  GET_DEMOGRAPHICS_BASELINE: (
    () => ({
      url: "/dashboards/demographics",
      method: "GET"
    })
  ),
  GET_GEOGRAPHY: (
    ({ id }) => ({
      url: `/dashboards/${id}/geography`,
      method: "GET"
    })
  ),
  GET_POLITICS: (
    ({ id }) => ({
      url: `/dashboards/${id}/politics`,
      method: "GET"
    })
  )
};

export const useMetricsApi: UseApiRequest<MetricsEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
