import {
  Box,
  Button,
  Layer,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from "grommet";
import {
  Edit,
  Search,
  Trash,
  User as UserIcon,
  UserAdd,
  UserAdmin
} from "grommet-icons";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { PageLayout } from "src/components/layout/PageLayout";
import { useUsersApi } from "src/utils/api/users.api";
import { Confirmation } from "src/components/modal/Confirmation";
import { ErrorModal } from "src/components/modal/Error";
import { Text } from "src/components/text/Text";
import { TextInput } from "src/components/form/TextInput";

import { CreateUpdateUser } from "../components/modal/CreateUpdateUser";
import { User } from "../models/User";
import { Error } from "../components/error/Error";
import {
  checkIsAdmin,
  CreateUpdateFormValues
} from "../utils/user-helper";

interface ErrorModalProps {
  title: string;
  error: string;
}
export const ManageUsersScreen: React.FC = () => {
  const history = useHistory();
  const [ getUsersResponse, getUsersRequest ] = useUsersApi("GET_USERS");
  const [ filteredUsers, setFilteredUsers ] = React.useState<User[]>([]);
  const [ createUserResponse, createUserRequest ] = useUsersApi("CREATE_USER");
  const [ updateUserResponse, updateUserRequest ] = useUsersApi("UPDATE_USER");
  const [ showCreateUpdate, setShowCreateUpdate ] = React.useState(false);
  const [ userToUpdate, setUserToUpdate ] = React.useState<User | null>(null);
  const [ deleteUserResponse, deleteUserRequest ] = useUsersApi("DELETE_USER");
  const [ showDeleteModal, setShowDeleteModal ] = React.useState(false);
  const [ userToDelete, setUserToDelete ] = React.useState<User | null>(null);
  const [ showErrorModal, setShowErrorModal ] = React.useState(false);

  const [ errorToShow, setErrorToShow ] = React.useState<ErrorModalProps>({
    title: "",
    error: ""
  });

  // get users

  React.useEffect(() => {
    getUsersRequest({});
  }, []);

  React.useEffect(() => {
    if (getUsersResponse.data) {
      setFilteredUsers(getUsersResponse.data);
    }
  }, [ getUsersResponse.data ]);

  const filterUsers = (filterString: string) => {
    const allUsers = getUsersResponse.data || [];

    const filtered = allUsers.filter(({ name, email }) => (
      name.toLowerCase().includes(filterString.toLowerCase()) ||
      email.toLowerCase().includes(filterString.toLowerCase())
    ));

    setFilteredUsers(filtered);
  };

  // create user

  const createUser = ({
    name = "",
    email = "",
    isAdmin = false
  }: CreateUpdateFormValues) => {
    createUserRequest({
      name,
      email,
      isAdmin
    });
  };

  React.useEffect(() => {
    if (createUserResponse.data) {
      setUserToUpdate(null);
      setShowCreateUpdate(false);
      history.go(0);
    } else if (createUserResponse.error) {
      setShowCreateUpdate(false);

      setErrorToShow({
        title: "Failed to create user",
        error: createUserResponse.error.details
      });
      setShowErrorModal(true);
    }
  }, [ createUserResponse ]);

  // update user

  const handleUserEditClick = (user: User) => {
    setUserToUpdate(user);
    setShowCreateUpdate(true);
  };

  const onCancelCreateUpdate = () => {
    setUserToUpdate(null);
    setShowCreateUpdate(false);
  };

  const updateUser = ({
    name = "",
    email = "",
    isAdmin = false
  }: CreateUpdateFormValues) => {
    if (userToUpdate) {
      updateUserRequest({
        id: userToUpdate.id.toString(10),
        name,
        email,
        isAdmin
      });
    }
  };

  React.useEffect(() => {
    if (updateUserResponse.data) {
      setUserToUpdate(null);
      setShowCreateUpdate(false);
      history.go(0);
    } else if (updateUserResponse.error) {
      setShowCreateUpdate(false);

      setErrorToShow({
        title: "Failed to update user",
        error: updateUserResponse.error.details
      });
      setShowErrorModal(true);
    }
  }, [ updateUserResponse ]);

  // delete user
  const handleUserDeleteClick = () => {
    if (userToDelete)
      deleteUserRequest({ id: userToDelete.id.toString(10) });
  };

  React.useEffect(() => {
    if (deleteUserResponse.complete && !deleteUserResponse.error) {
      history.go(0);
    } else if (deleteUserResponse.error) {
      setErrorToShow({
        title: "Failed to delete user",
        error: deleteUserResponse.error.details
      });
      setShowErrorModal(true);
    }
  }, [ deleteUserResponse ]);

  if (getUsersResponse.error) {
    return (
      <Error error={getUsersResponse.error}/>
    );
  }

  if (getUsersResponse.pending || !getUsersResponse.data) {
    return (
      <Box align="center" fill="vertical" justify="center">
        <Spinner size="large" />
      </Box>
    );
  }

  return (
    <PageLayout
      title="Manage Users"
      HeaderRight={(
        <Button
          icon={<UserAdd color="brand" />}
          onClick={() => setShowCreateUpdate(true)}
          disabled={showCreateUpdate}
          aria-label="Add New User"
        />
      )}
      showBackButton
    >
      <Box width="medium" pad="medium" alignSelf="center">
        <TextInput
          size="medium"
          placeholder={"Search for user..."}
          icon={<Search />}
          onChange={event => filterUsers(event.target.value)}
        />
      </Box>

      <Box pad="medium">
        {getUsersResponse.error ? (
          <Error error={getUsersResponse.error} />
        ) : (
          <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell size="xxsmall" title="User Role" scope="col"/>
                <TableCell size="1/4" scope="col">
                  <Text as="h2" bold>Name</Text>
                </TableCell>
                <TableCell size="3/4" scope="col">
                  <Text as="h2" bold>Email</Text>
                </TableCell>
                <TableCell size="xsmall" scope="col">
                  <Text as="h2" bold>Actions</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredUsers.map(user => {
                const isAdmin = checkIsAdmin(user);

                return (
                  <TableRow key={user.id}>
                    <TableCell scope="row">
                      {isAdmin ? (
                        <UserAdmin color="black" aria-label="Admin User" />
                      ) : (
                        <UserIcon color="black" aria-label="Non-Admin User" />
                      )}
                    </TableCell>
                    <TableCell scope="row">
                      <Text as="span">{ user.name }</Text>
                    </TableCell>
                    <TableCell scope="row">
                      <Text as="span">{ user.email }</Text>
                    </TableCell>
                    <TableCell scope="row" direction="row" gap="xsmall">
                      <Button onClick={() => handleUserEditClick(user)} aria-label={`Edit User ${user.name}`}>
                        <Edit color="black" />
                      </Button>
                      <Button onClick={() => {
                        setShowDeleteModal(true);
                        setUserToDelete(user);
                      }}
                        aria-label={`Delete User ${user.name}`}
                      >
                        <Trash color="error" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </TableWrapper>
        )}

        {showCreateUpdate && (
          <Layer
            onEsc={() => setShowCreateUpdate(false)}
            onClickOutside={() => setShowCreateUpdate(false)}
          >
            <CreateUpdateUser
              user={userToUpdate}
              createUpdateUser={userToUpdate ? updateUser : createUser}
              pendingResponse={userToUpdate ? updateUserResponse.pending : createUserResponse.pending}
              onCancel={onCancelCreateUpdate}
            />
          </Layer>
        )}

        {showDeleteModal && (
          <Layer
            onEsc={() => setShowDeleteModal(false)}
            onClickOutside={() => setShowDeleteModal(false)}
          >
            <Confirmation
              title={`Are you sure you want to delete "${userToDelete?.name}"?`}
              onSubmit={handleUserDeleteClick}
              submitText={"Delete"}
              onCancel={() => setShowDeleteModal(false)}
              cancelText={"Cancel"}
              pending={deleteUserResponse.pending}
            />
          </Layer>
        )}

        {showErrorModal && (
          <Layer
            onEsc={() => setShowErrorModal(false)}
            onClickOutside={() => setShowErrorModal(false)}
          >
            <ErrorModal title={errorToShow.title} text={errorToShow.error} onSubmit={() => setShowErrorModal(false)} submitText={"Okay"}/>
          </Layer>
        )}
      </Box>
    </PageLayout>
  );
};

const TableWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  width: 100%;
  max-width: 85rem;
  align-self: center;
  border-radius: 0.25rem;

  table {
    text-align: left;
    position: relative;
    border-collapse: collapse; 
  }

  th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #f8f8f8;
  }
  
  th[scope=row] {
    position: sticky;
    left: 0;
    z-index: 1;
  }
`;