import countBy from "lodash.countby";
import moment from "moment/moment";
import { generate } from "patternomaly";

import {
  DemographicsBaselineData,
  DemographicsDataSections,
  DemographicType
} from "src/models/DemographicsData";
import {
  PartyName,
  PoliticsData
} from "src/models/PoliticsData";
import {
  ChartData,
  ChartDataset
} from "src/models/ChartData";
import { ChartTypes } from "src/models/ChartTypes";
import { DataPoint } from "src/models/DataPoint";

import {
  copper,
  copperCharts,
  politicalPartyColours
} from "../theme/copper-theme";

const BASELINE_GEOGRAPHY_CODE = "2092957703";
const BASELINE_STRUCTURE_CHANGED = 1632144522639;
const sumFromDataset = (dataset: DataPoint[]) => dataset.map(point => point.value).reduce((pointA, pointB) => pointA + pointB);
const getPercentage = (rawValue: number, totalItems: number) => (rawValue / totalItems) * 100;

export const getBaselineDataset = (dataset: DataPoint[] | undefined): ChartDataset | null => {
  if (!dataset) {
    return null;
  }
  const datasetTotalPeople = sumFromDataset(dataset);

  return {
    label: "UK Baseline",
    data: dataset.map(point => getPercentage(point.value, datasetTotalPeople)),
    backgroundColor: copperCharts[ 1 ]
  };
};

export const getChartData = (dataset: DataPoint[] | undefined, datasetName: string, chartType: ChartTypes, usePattern: boolean): ChartData | null => {
  if (!dataset) {
    return null;
  }
  const datasetTotalPeople = sumFromDataset(dataset);

  return {
    labels: dataset.map(point => point.label),
    datasets: [
      {
        label: datasetName,
        data: dataset.map(point => getPercentage(point.value, datasetTotalPeople)),
        backgroundColor: getChartColours(chartType, usePattern)
      }
    ]
  };
};

export const checkBaselineLastUpdate = (): boolean => {
  const lastUpdated = localStorage.getItem("demographicsBaselineUpdatedAt");

  const shouldFetchBaselineData =
    // if never fetched
    !lastUpdated ||
    // >1 week ago
    moment(lastUpdated).isBefore(moment().subtract(7, "days")) ||
    // before structure changed
    parseInt(lastUpdated) < BASELINE_STRUCTURE_CHANGED;

  return shouldFetchBaselineData;
};

export const getBaselineData = (datasetName: DemographicType, formatted = true): DataPoint[] | null => {
  const baselineString = localStorage.getItem("demographicsBaseline");

  if (!baselineString) {
    return null;
  }

  const allBaselineData: DemographicsBaselineData = JSON.parse(baselineString);
  const baselineData = allBaselineData[ datasetName ][ BASELINE_GEOGRAPHY_CODE ];

  if (!formatted) {
    return baselineData;
  }

  const baselineTotalPeople = sumFromDataset(baselineData);

  const baselineDataFormatted = baselineData.map(({ label, value }) => ({
    label,
    value: getPercentage(value, baselineTotalPeople)
  }));

  return baselineDataFormatted;
};

export const setBaselineData = (data: DemographicsDataSections): void => {
  localStorage.setItem("demographicsBaseline", JSON.stringify(data));
  localStorage.setItem("demographicsBaselineUpdatedAt", Date.now().toString(10));
};

export const getChartDataWithBaseline = (
  dataset: DataPoint[],
  baselineDatasetName: DemographicType,
  datasetName: DemographicType,
  chartType: ChartTypes,
  usePattern: boolean
): ChartData => {
  const baselineString = localStorage.getItem("demographicsBaseline");
  const baselineData: DemographicsBaselineData | null = baselineString ? JSON.parse(baselineString) : null;
  const datasetTotalPeople = sumFromDataset(dataset);

  const datasets: ChartDataset[] = [
    {
      label: datasetName,
      data: dataset.map(point => getPercentage(point.value, datasetTotalPeople)),
      backgroundColor: getChartColours(chartType, usePattern)
    }
  ];

  if (baselineData) {
    const baselineDataset = baselineData[ baselineDatasetName ][ BASELINE_GEOGRAPHY_CODE ];
    const baselineTotalPeople = sumFromDataset(baselineDataset);

    datasets.push({
      label: "England & Wales average",
      data: baselineDataset.map((point: DataPoint) => getPercentage(point.value, baselineTotalPeople)),
      backgroundColor: copper.global.colors.brand
    });
  }

  return {
    labels: dataset.map(point => point.label),
    datasets
  };
};

export const getChartDataFromPoliticsData = (dataset: PoliticsData, chartType: ChartTypes, usePattern: boolean): ChartData => {
  // Calculate proportion of all elected parties across wards
  let allPartyOccurrences: string[] = [];

  dataset.features.forEach(ward => {
    // Get elected party names for each ward
    const electedParties = (ward.properties.latestElectionResult || [])
      .filter(individualResult => individualResult.elected)
      .map(result => result.party.name);

    // Add to array with full list of parties elected in selected wards (with repetition)
    allPartyOccurrences = allPartyOccurrences.concat(electedParties);
  });

  const partyNumbers = countBy(allPartyOccurrences);

  return {
    labels: Object.keys(partyNumbers),
    datasets: [
      {
        label: "Ward Party Data",
        data: Object.values(partyNumbers),
        backgroundColor: getPoliticalPartyColours(Object.keys(partyNumbers), usePattern)
      }
    ]
  };
};

export const getChartColours = (chartType: ChartTypes, usePattern: boolean): string | string[] | CanvasPattern[] => {
  switch (chartType) {
    case ChartTypes.Bar: {
      return copperCharts[ 0 ];
    }
    case ChartTypes.Pie: {
      return usePattern ? generate(copperCharts) : copperCharts;
    }
    default: {
      return copperCharts[ 0 ];
    }
  }
};

const getPoliticalPartyColours = (partyNames: string[], usePattern: boolean) => {
  const colourList = partyNames.map(party => politicalPartyColours[ party ] || politicalPartyColours[ PartyName.Independent ]);

  return usePattern ? generate(colourList) : colourList;
};