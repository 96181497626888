import { Button as GrommetButton } from "grommet";
import styled from "styled-components";

export const Button = styled(GrommetButton)`
  font-family: 'Merriweather Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  color: ${({ primary, theme }) => primary ? "white" : theme.colors.brand};
  background-color: ${({ primary, theme }) => primary ? theme.colors.brand : "#f8f8f8"};
`;