import React from "react";
import {
  Box,
  Spinner
} from "grommet";
import { Parser } from "json2csv";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  Download,
  Search
} from "grommet-icons";
import styled from "styled-components";

import { Button } from "src/components/button/Button";
import { Text } from "src/components/text/Text";
import { EconomicActivityDoughnut } from "src/components/chart/EconomicActivityDoughnut";
import { IndustryBar } from "src/components/chart/IndustryBar";
import { QualificationLevelDoughnut } from "src/components/chart/QualificationLevelDoughnut";
import { ClaimantCount } from "src/components/chart/ClaimantCount";
import { HousingTenureDoughnut } from "src/components/chart/HousingTenureDoughnut";
import { AgeBar } from "src/components/chart/AgeBar";
import { Error } from "src/components/error/Error";
import { getChartData } from "src/utils/chart-data-helper";
import { ApiError } from "src/utils/api/types";
import { ChartTypes } from "src/models/ChartTypes";
import { DemographicsData } from "src/models/DemographicsData";
import { PoliticalFeatureProperties } from "src/models/PoliticsData";

import { SelectedAreaCard } from "../card/SelectedAreaCard";
import { SetSelectedArea } from "../map/PopupsContainer";

interface DemographicsProps {
  id: string;
  data: DemographicsData | null;
  loading: boolean;
  error: ApiError | null;
  selectedCouncil: PoliticalFeatureProperties | null;
  setSelectedCouncil: SetSelectedArea;
  selectedWard: PoliticalFeatureProperties | null;
  setSelectedWard: SetSelectedArea;
  withBaseline: boolean;
  usePatterns: boolean;
}

export const Demographics: React.FC<DemographicsProps> = ({
  id,
  data,
  loading,
  error,
  selectedCouncil,
  setSelectedCouncil,
  selectedWard,
  setSelectedWard,
  withBaseline,
  usePatterns
}) => {
  const dataToDisplay = React.useMemo(() => {
    if (!data) {
      return null;
    }

    if (selectedWard) {
      return data.demographics[ selectedWard.codes.gss ];
    } else if (selectedCouncil) {
      return data.demographics[ selectedCouncil.codes.gss ];
    } else {
      return null;
    }
  }, [
    selectedCouncil,
    selectedWard,
    data
  ]);

  const economicActivityChartData = React.useMemo(() => getChartData(
    dataToDisplay?.economicActivity,
    "Selected Area",
    ChartTypes.Pie,
    usePatterns
  ), [ dataToDisplay?.economicActivity, usePatterns ]);

  const industryChartData = React.useMemo(() => getChartData(
    dataToDisplay?.industry,
    "Selected Area",
    ChartTypes.Bar,
    usePatterns
  ), [ dataToDisplay?.industry, usePatterns ]);

  const qualificationLevelChartData = React.useMemo(() => getChartData(
    dataToDisplay?.qualificationLevel,
    "Selected Area",
    ChartTypes.Pie,
    usePatterns
  ), [ dataToDisplay?.qualificationLevel, usePatterns ]);

  const housingTenureChartData = React.useMemo(() => getChartData(
    dataToDisplay?.housingTenure,
    "Selected Area",
    ChartTypes.Pie,
    usePatterns
  ), [ dataToDisplay?.housingTenure, usePatterns ]);

  const ageChartData = React.useMemo(() => getChartData(
    dataToDisplay?.age,
    "Selected Area",
    ChartTypes.Bar,
    usePatterns
  ), [ dataToDisplay?.age, usePatterns ]);

  const downloadCSV = () => {
    const json2csvParser = new Parser();

    if (dataToDisplay) {
      const areaNameSlug = (selectedWard || selectedCouncil)?.name.replace(/\s+/g, "-").toLowerCase();
      const economicActivityCsv = json2csvParser.parse(dataToDisplay.economicActivity);
      const industryCsv = json2csvParser.parse(dataToDisplay.industry);
      const qualificationLevelCsv = json2csvParser.parse(dataToDisplay.qualificationLevel);
      const housingTenureCsv = json2csvParser.parse(dataToDisplay.housingTenure);
      const ageCsv = json2csvParser.parse(dataToDisplay.age);
      const zip = new JSZip();

      zip.file("economic-activity.csv", economicActivityCsv);
      zip.file("industry.csv", industryCsv);
      zip.file("qualification-level.csv", qualificationLevelCsv);
      zip.file("housing-tenure.csv", housingTenureCsv);
      zip.file("age.csv", ageCsv);

      zip.generateAsync({ type: "blob" }).then(
        content => saveAs(content, `${areaNameSlug || id}-demographics.zip`)
      );
    }
  };

  if (error) {
    return (
      <Error error={error} />
    );
  }

  if (loading) {
    return (
      <Box align="center" fill="vertical" justify="center" alignSelf="center" margin="auto">
        <Spinner size="large" />
      </Box>
    );
  }

  return !dataToDisplay ? (
    <Box align="center" fill="vertical" justify="center" alignSelf="center" pad="medium">
      <Search size="large" color="brand" />
      <AreaSelectMessageText>
        Select an area on the map to get started
      </AreaSelectMessageText>
    </Box>
  ) : (
    <Box gap="medium" pad="small">
        <Box align="stretch" pad={{ top: "small" }}>
          <Text as="small" style={{ textAlign: "center" }}>
            Note: Demographics data is only reliably available for England &amp; Wales
          </Text>

          <Box
            direction="row"
            justify="between"
            align="center"
            flex
            margin={{
              top: "1rem",
              left: "-0.5rem"
            }}
          >
            <Box direction="row" wrap>
              {!!selectedCouncil && (
                <SelectedAreaCard
                  area={selectedCouncil}
                  onClear={() => setSelectedCouncil(null)}
                />
              )}
              {!!selectedWard && (
                <SelectedAreaCard
                  area={selectedWard}
                  onClear={() => setSelectedWard(null)}
                />
              )}
            </Box>

            <Button
              icon={<Download size="20px" color="brand" />}
              label="CSV"
              title="Download data as CSV"
              onClick={downloadCSV}
            />
          </Box>
      </Box>

      <EconomicActivityDoughnut data={economicActivityChartData} withBaseline={withBaseline} />

      <IndustryBar data={industryChartData} withBaseline={withBaseline} />

      <QualificationLevelDoughnut data={qualificationLevelChartData} withBaseline={withBaseline} />

      <ClaimantCount data={dataToDisplay.claimantCount} withBaseline={withBaseline} />

      <HousingTenureDoughnut data={housingTenureChartData} withBaseline={withBaseline} />

      <AgeBar data={ageChartData} withBaseline={withBaseline} />
    </Box>
  );
};

const AreaSelectMessageText = styled(Text).attrs({ as: "h2" })`
  font-size: 2rem;
  text-align: center;
`;