import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from "axios";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  ApiResponse,
  AxiosRequestInitialiser,
  LambdaError,
  UseAxiosReturnValue
} from "src/utils/api/types";

import { useAuthContext } from "./useAuthContext";

interface RequestDefinition<Params> {
  config: AxiosRequestConfig;
  params: Params;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const useAxios = <ResponseData, Params>(initialiser: AxiosRequestInitialiser<any>): UseAxiosReturnValue<ResponseData, Params> => {
  const { authDataState, setAuthDataState } = useAuthContext();
  const history = useHistory();

  const axiosInstance = React.useMemo(() => axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 15000,
    headers: authDataState.authenticated ? { Authorization: `Bearer ${authDataState.idToken}` } : undefined
  }), [ authDataState ]);

  const [ requestDefinition, setRequestDefinition ] = React.useState<RequestDefinition<Params> | null>(null);

  const triggerRequest = React.useCallback((params: Params) => setRequestDefinition({
    config: initialiser(params),
    params
  }), []);

  const [ response, setResponse ] = React.useState<ApiResponse<ResponseData>>({
    data: null,
    error: null,
    pending: false,
    complete: false
  });

  const onResponseSuccess = React.useCallback((axiosResponse: AxiosResponse<ApiResponse<ResponseData>>) => {
    setResponse({
      data: axiosResponse.data.data,
      error: null,
      pending: false,
      complete: true
    });
  }, []);

  const onResponseError = React.useCallback((axiosError: AxiosError<LambdaError>) => {
    if (axiosError.response) {
      if (axiosError.response.status === 403) {
        // check for valid user session
        Auth.currentAuthenticatedUser({ bypassCache: true }).then((user: CognitoUser) => {
          const idToken = user.getSignInUserSession()?.getIdToken();

          if (!idToken) {
            throw new Error("No ID token");
          }

          const jwt = idToken.getJwtToken();
          const expiry = idToken.getExpiration();
          const tokenExpired = Date.now() - (expiry * 1000) > 0;

          // if current token is valid and different to one in auth state
          if (!tokenExpired && jwt && jwt !== authDataState.idToken) {
            // update auth state with refreshed JWT
            setAuthDataState({
              authenticated: true,
              error: null,
              authenticating: false,
              idToken: jwt
            });
            // refresh page
            history.go(0);
          } else {
            setResponse({
              data: null,
              error: {
                id: "",
                code: "UNAUTHORIZED",
                status: 403,
                title: "Unauthorised",
                details: "The user is not authorised to access this resource"
              },
              pending: false,
              complete: true
            });
          }
        // sign out and redirect to login if unauthenticated
        }).catch(() => {
          Auth.signOut();

          setAuthDataState({
            authenticated: false,
            authenticating: false,
            error: null,
            idToken: ""
          });
        });
      } else {
        const error = axiosError.response.data.errors[ 0 ];

        setResponse({
          data: null,
          error,
          pending: false,
          complete: true
        });
      }
    } else {
      setResponse({
        data: null,
        error: {
          id: "",
          code: "CONNECTION_REFUSED",
          status: 500,
          title: "Connection Refused",
          details: "This service is unavailable. Please try again later."
        },
        pending: false,
        complete: true
      });
    }
  }, []);

  React.useEffect(() => {
    if (!requestDefinition) {
      return;
    }

    // set loading state
    setResponse({
      data: null,
      error: null,
      pending: true,
      complete: false
    });

    // make request
    axiosInstance(requestDefinition.config)
      .then(onResponseSuccess)
      .catch(onResponseError);
  }, [ requestDefinition ]);

  return [ response, triggerRequest ];
};