import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Grommet } from "grommet";
import Amplify from "aws-amplify";
import {
  ThemeProvider,
  createGlobalStyle
} from "styled-components";

import { ActiveDashboardProvider } from "src/context/ActiveDashboardContext";

import { DashboardsScreen } from "./screens/Dashboards";
import { DashboardDetailScreen } from "./screens/DashboardDetail";
import { LoginScreen } from "./screens/Login";
import { ManageUsersScreen } from "./screens/ManageUsers";
import {
  copper,
  globalTheme
} from "./theme/copper-theme";
import { AuthProvider } from "./context/AuthContext";
import { NewDashboardScreen } from "./screens/NewDashboard";
import { EditDashboardScreen } from "./screens/EditDashboard";
import { PrivateRoute } from "./components/navigation/PrivateRoute";
import { PageNotFound } from "./components/error/PageNotFound";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
  }
  :root {
    --amplify-font-family: 'Merriweather Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    --amplify-text-xxs: 1.2rem;
    --amplify-text-xs: 1.3rem;
    --amplify-text-sm: 1.4rem;
    --amplify-text-md: 1.6rem;
    --amplify-text-md-sub: 1.84rem;
    --amplify-text-lg: 2.4rem;
    --amplify-text-xl: 3.2rem;
    --amplify-text-xxl: 4rem;
    --amplify-primary-color: ${({ theme }) => theme.colors.brand};
    --amplify-primary-tint: ${({ theme }) => theme.colors.brand};
    --amplify-primary-shade: ${({ theme }) => theme.colors.brand};
  }
`;

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  }
});

const App: React.FC = () => (
  <AuthProvider>
    <ActiveDashboardProvider>
      <Grommet theme={copper} full style={{ overflow: "hidden" }}>
        <ThemeProvider theme={globalTheme}>
          <GlobalStyle />
          <Router>
            <Switch>
              {/* public routes */}
              <Route exact path="/">
                <Redirect to="/dashboards" from="/" />
              </Route>

              <Route exact path="/login">
                <LoginScreen />
              </Route>

              {/* private routes */}
              <PrivateRoute exact path="/dashboards">
                <DashboardsScreen />
              </PrivateRoute>

              <PrivateRoute exact path="/dashboards/new">
                <NewDashboardScreen />
              </PrivateRoute>

              <PrivateRoute exact path="/dashboards/:id">
                <DashboardDetailScreen />
              </PrivateRoute>

              <PrivateRoute exact path="/dashboards/:id/edit">
                <EditDashboardScreen />
              </PrivateRoute>

              <PrivateRoute exact path="/users">
                <ManageUsersScreen />
              </PrivateRoute>

              {/* 404 */}
              <Route path="*">
                <PageNotFound />
              </Route>

            </Switch>
          </Router>
        </ThemeProvider>
      </Grommet>
    </ActiveDashboardProvider>
  </AuthProvider>
);

export default App;
