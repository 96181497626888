import mapboxgl from "mapbox-gl";

import {
  PartyName,
  GeoJSONPoliticalFeature,
  PoliticalFeatureProperties
} from "src/models/PoliticsData";
import {
  addMapDataSource,
  addMapLayer,
  showMapLayer,
  hideMapLayer,
  getElectedPartyNames,
  getPartyColour
} from "src/utils/map-data-helper";

interface PoliticsAreaMapMetadata {
  sourceId: string;
  fillId: string;
  strokeId: string;
}

export const getPoliticsAreaMapMetadata = (featureProperties: PoliticalFeatureProperties): PoliticsAreaMapMetadata => ({
  sourceId: `politics-ward-${featureProperties.id}-source`,
  fillId: `politics-ward-${featureProperties.id}-fill`,
  strokeId: `politics-ward-${featureProperties.id}-stroke`
});

export const addPoliticsAreaToMap = (
  map: mapboxgl.Map,
  feature: GeoJSONPoliticalFeature
): void => {
  const {
    sourceId,
    fillId,
    strokeId
  } = getPoliticsAreaMapMetadata(feature.properties);

  addMapDataSource(map, sourceId, feature);

  const electedParties = getElectedPartyNames(feature.properties.latestElectionResult || []);
  const mainColour = getPartyColour(electedParties);
  const lineColour = electedParties[ 0 ] === PartyName.Independent ? "#B0B0B0" : mainColour;

  // add semi-transparent fill
  addMapLayer(map, {
    id: fillId,
    type: "fill",
    source: sourceId,
    paint: {
      "fill-color": mainColour,
      "fill-opacity": 0.5
    }
  });

  // add stroke
  addMapLayer(map, {
    id: strokeId,
    type: "line",
    source: sourceId,
    paint: {
      "line-color": lineColour,
      "line-width": 2,
      "line-opacity": electedParties.length > 0 ? 1 : 0.25
    }
  });
};

export const showPoliticsArea = (map: mapboxgl.Map, featureProperties: PoliticalFeatureProperties): void => {
  const { fillId, strokeId } = getPoliticsAreaMapMetadata(featureProperties);

  showMapLayer(map, fillId);
  showMapLayer(map, strokeId);
};

export const hidePoliticsArea = (map: mapboxgl.Map, featureProperties: PoliticalFeatureProperties): void => {
  const { fillId, strokeId } = getPoliticsAreaMapMetadata(featureProperties);

  hideMapLayer(map, fillId);
  hideMapLayer(map, strokeId);
};