import React from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Spinner
} from "grommet";

import { Text } from "src/components/text/Text";
import { Button } from "src/components/button/Button";

interface ConfirmationProps {
  title: string;
  onSubmit: () => void;
  submitText: string;
  onCancel: () => void;
  cancelText: string;
  pending: boolean;
}

export const Confirmation: React.FC<ConfirmationProps> = ({
  title, onSubmit, submitText, onCancel, cancelText, pending
}) => {
  return (
      <Card width="medium" pad="medium">
        <CardHeader alignContent="center">
          <Text as="h2" style={{ textAlign: "center" }}>
            {title}
          </Text>
        </CardHeader>
        <CardBody pad={{ top: "medium" }} align="center">
          <Box direction="row" gap="medium" pad={{ top: "medium" }}>
            <Button
              primary
              label={pending ? (
                <Spinner size="small" color="white"/>
              ) : (
                submitText
              )}
              onClick={onSubmit}
              disabled={pending}
            />
            <Button label={cancelText} onClick={() => onCancel()} />
          </Box>
        </CardBody>
      </Card>
  );
};