export enum PartyName {
  Conservative = "Conservative and Unionist Party",
  Labour = "Labour Party",
  LiberalDemocrats = "Liberal Democrats",
  Green = "Green Party",
  UKIP = "UK Independence Party (UKIP)",
  LabourAndCooperative = "Labour and Co-operative Party",
  DemocraticUnionistParty = "Democratic Unionist Party - D.U.P.",
  SinnFein = "Sinn Féin",
  AllianceNI = "Alliance - Alliance Party of Northern Ireland",
  SocialDemocraticLabourParty = "SDLP (Social Democratic & Labour Party)",
  ProgressiveUnionistPartyNI = "Progressive Unionist Party of Northern Ireland",
  UlterUnionistParty = "Ulster Unionist Party",
  ScottishNationalParty = "Scottish National Party (SNP)",
  ScottishGreen = "Scottish Green Party",
  PlaidCymru = "Plaid Cymru - The Party of Wales",
  Independent = "Independent"
}

export interface PartyAreaResults {
  [key: string]: number
}

export interface PoliticsData {
  type: "FeatureCollection";
  features: GeoJSONPoliticalFeature[];
}

export interface GeoJSONPoliticalFeature {
  type: "Feature";
  geometry: {
    type: "Polygon";
    coordinates: number[][][];
  }
  properties: PoliticalWardFeatureProperties
}

export interface PoliticalWardFeatureProperties extends PoliticalFeatureProperties {
  latestElectionId: string;
  latestElectionResult?: ElectionResult[];
  parent: PoliticalFeatureProperties;
}

export interface PoliticalFeatureProperties {
  id: number;
  name: string;
  parent_area: number | null;
  generation_high: number;
  all_names: Record<string, string>;
  codes: Record<string, string>;
  country: string;
  type_name: string;
  generation_low: number;
  country_name: string;
  type: string;
}

export interface ElectionResult {
  person: {
    id: number;
    url: string;
    name: string;
  }
  party: {
    url: string;
    ec_id: string;
    name: string;
    legacy_slug: string;
  }
  num_ballots: number;
  elected: boolean;
}