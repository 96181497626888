import React from "react";
import { Doughnut } from "react-chartjs-2";

import { Card } from "src/components/card/Card";
import { useActiveDashboardSlug } from "src/hooks/useActiveDashboardSlug";
import { useChartTooltip } from "src/hooks/useTooltip";
import { ChartProps } from "src/models/ChartData";
import { getBaselineData } from "src/utils/chart-data-helper";

import { Tooltip } from "./Tooltip";
import onsLogo from "../../assets/ons-logo.png";

export const QualificationLevelDoughnut: React.FC<ChartProps> = ({ data, withBaseline }) => {
  const dashboardSlug = useActiveDashboardSlug();
  const [ tooltipData, tooltipConfig ] = useChartTooltip<"doughnut">();
  const baselineData = React.useMemo(() => withBaseline ? getBaselineData("qualificationLevel") : null, [ withBaseline ]);

  const DoughnutChart = React.useMemo(() => (
    <Doughnut
      data={data}
      options={{
        responsive: true,
        aspectRatio: 2.25,
        plugins: {
          legend: {
            position: "right",
            labels: {
              boxWidth: 30,
              font: { size: 11 }
            }
          },
          tooltip: tooltipConfig
        }
      }}
    />
  ), [ data ]);

  return (
    <Card
      headerText="Highest Level of Qualification (Workplace Population)"
      dataSource={{
        logo: onsLogo,
        name: "NOMIS WP501EW (2011)",
        link: "https://www.nomisweb.co.uk/query/construct/summary.asp?reset=yes&mode=construct&dataset=1311&version=0&anal=1&initsel="
      }}
      fill="horizontal"
      fileName={`qualification-level-${dashboardSlug}`}
    >
      {DoughnutChart}
      <Tooltip data={tooltipData} baselineData={baselineData} dataType="percentage" />
    </Card>
  );
};
