import React from "react";
import {
  Box,
  Button
} from "grommet";
import { Close } from "grommet-icons";
import styled from "styled-components";

import { PoliticalFeatureProperties } from "src/models/PoliticsData";

import { Text } from "../text/Text";

interface SelectedAreaCardProps {
  area: PoliticalFeatureProperties;
  onClear: () => void;
}

export const SelectedAreaCard: React.FC<SelectedAreaCardProps> = ({ area, onClear }) => {
  const type = area.parent_area === null ? "council" : "ward";

  return (
    <CardWrapper direction="row" pad="1rem">
      <Box direction="column" pad={{ right: "2rem" }}>
        <AreaTypeText>
          Selected {type}
        </AreaTypeText>
        <AreaNameText>
          {area.name}
        </AreaNameText>
      </Box>
      <Button
        icon={<Close size="20" color="brand" />}
        onClick={onClear}
        style={{
          padding: "0.5rem",
          marginLeft: "1rem"
        }}
      />
    </CardWrapper>
  );
};

const CardWrapper = styled(Box)`
  border-radius: 1rem;
  background-color: #F8F8F8;
  margin: 0.5rem;
`;

const AreaTypeText = styled(Text).attrs({ as: "small" })`
  color: grey;
`;

const AreaNameText = styled(Text).attrs({ as: "h3" })`
  margin-top: 0.25rem;
  margin-bottom: 0;
`;