import React from "react";
import {
  useHistory,
  useParams
} from "react-router-dom";
import {
  Box,
  Button,
  Spinner,
  Tab,
  Tabs,
  Tip
} from "grommet";
import {
  Briefcase,
  Edit,
  Navigate,
  Paint,
  TreeOption
} from "grommet-icons";
import circle from "@turf/circle";
import styled from "styled-components";
import mapboxgl from "mapbox-gl";

import { Text } from "src/components/text/Text";
import { Politics } from "src/components/tab-content/Politics";
import {
  calculateZoomLevel,
  MapDataType
} from "src/utils/map-data-helper";
import { Error } from "src/components/error/Error";
import { PageLayout } from "src/components/layout/PageLayout";
import {
  useMapboxContainer,
  useMapbox,
  useDemographicsMapFeatures,
  useGeographyMapFeatures,
  usePoliticsMapFeatures
} from "src/hooks/map";
import { useMetricsApi } from "src/utils/api/metrics.api";
import { useDashboardsApi } from "src/utils/api/dashboards.api";
import { Demographics } from "src/components/tab-content/Demographics";
import { Geography } from "src/components/tab-content/Geography";
import { useActiveDashboardContext } from "src/hooks/useActiveDashboardContext";
import { copperCharts } from "src/theme/copper-theme";
import {
  checkBaselineLastUpdate,
  setBaselineData
} from "src/utils/chart-data-helper";
import { PopupsContainer } from "src/components/map/PopupsContainer";
import { PoliticalFeatureProperties } from "src/models/PoliticsData";
import { updateWardHighlight } from "src/hooks/map/utils/demographics";

interface DashboardDetailParams {
  id: string;
}

export const DashboardDetailScreen: React.FC = () => {
  const { id } = useParams<DashboardDetailParams>();
  const history = useHistory();
  const [ usePatterns, setUsePatterns ] = React.useState(false);
  const { setActiveDashboard } = useActiveDashboardContext();
  const [ activeTabIndex, setActiveTabIndex ] = React.useState(0);
  // get dashboard info
  const [ getDashboardResponse, getDashboardRequest ] = useDashboardsApi("GET_DASHBOARD");
  const location = getDashboardResponse.data?.location;
  // get metrics data
  const [ getDemographicsResponse, getDemographicsRequest ] = useMetricsApi("GET_DEMOGRAPHICS");
  const [ getGeographyResponse, getGeographyRequest ] = useMetricsApi("GET_GEOGRAPHY");
  const [ getPoliticsResponse, getPoliticsRequest ] = useMetricsApi("GET_POLITICS");
  // get baseline data
  const [ baselineDataReady, setBaselineDataReady ] = React.useState(false);
  const [ getDemographicsBaselineResponse, getDemographicsBaselineRequest ] = useMetricsApi("GET_DEMOGRAPHICS_BASELINE");
  // map setup
  const container = useMapboxContainer();

  const [ map, mapLoaded ] = useMapbox({
    container,
    center: location ? {
      lat: location.latitude,
      lon: location.longitude
    } : undefined,
    zoom: calculateZoomLevel(location)
  });

  // set council + ward data when selected on the map
  const [ selectedCouncil, setSelectedCouncil ] = React.useState<PoliticalFeatureProperties | null>(null);
  const [ selectedWard, setSelectedWard ] = React.useState<PoliticalFeatureProperties | null>(null);

  // on selected ward change
  React.useEffect(() => {
    if (map && mapLoaded && selectedWard) {
      // highlight selected ward
      updateWardHighlight(map, selectedWard, true);

      // clear last highlight on change
      return () => {
        updateWardHighlight(map, selectedWard, false);
      };
    }
  }, [ selectedWard ]);

  // clear selected ward when council changes
  React.useEffect(() => {
    setSelectedWard(null);
  }, [ selectedCouncil ]);

  // render demographics features on map
  useDemographicsMapFeatures(
    map,
    mapLoaded,
    activeTabIndex === MapDataType.Demographics,
    getDemographicsResponse.data,
    selectedCouncil
  );

  // render geography features on map
  useGeographyMapFeatures(
    map,
    mapLoaded,
    activeTabIndex === MapDataType.Geography,
    getGeographyResponse.data
  );

  // render politics features on map
  usePoliticsMapFeatures(
    map,
    mapLoaded,
    activeTabIndex === MapDataType.Politics,
    getPoliticsResponse.data
  );

  // Get baseline data
  React.useEffect(() => {
    const shouldUpdateBaseline = checkBaselineLastUpdate();

    if (shouldUpdateBaseline) {
      getDemographicsBaselineRequest({});
    } else {
      setBaselineDataReady(true);
    }
  }, []);

  // Handle baseline response
  React.useEffect(() => {
    if (getDemographicsBaselineResponse.data) {
      setBaselineData(getDemographicsBaselineResponse.data);
      setBaselineDataReady(true);
    }
  }, [ getDemographicsBaselineResponse.data ]);

  // Get dashboard data
  React.useEffect(() => {
    getDashboardRequest({ id });
    getDemographicsRequest({ id });
    getGeographyRequest({ id });
    getPoliticsRequest({ id });
  }, []);

  // Handle dashboard response
  React.useEffect(() => {
    if (map && mapLoaded && getDashboardResponse.data) {
      setActiveDashboard(getDashboardResponse.data);

      const {
        latitude,
        longitude,
        radius
      } = getDashboardResponse.data.location;

      new mapboxgl.Marker({ color: copperCharts[ 0 ] })
        .setLngLat([ longitude, latitude ])
        .addTo(map);

      const dashboardArea = circle([ longitude, latitude ], radius, { steps: 60 });

      map.addLayer({
        id: "selected-area",
        type: "fill",
        source: {
          type: "geojson",
          data: dashboardArea
        },
        paint: {
          "fill-color": "rgba(54, 92, 115, 0.05)",
          "fill-outline-color": copperCharts[ 0 ]
        }
      });
    }
  }, [
    map,
    mapLoaded,
    getDashboardResponse.data
  ]);

  const handleDashboardEdit = () => {
    history.push(`/dashboards/${id}/edit`);
  };

  if (getDashboardResponse.error) {
    return (
      <Error error={getDashboardResponse.error} />
    );
  }

  if (getDashboardResponse.pending || !getDashboardResponse.data) {
    return (
      <Box justify="center" align="center" fill="vertical">
        <Spinner size="large" />
      </Box>
    );
  }

  return (
    <PageLayout
      title={`${getDashboardResponse.data.name} (${getDashboardResponse.data.location.radius} km radius)`}
      HeaderRight={(
        <div>
          <Tip content={`${usePatterns ? "Disable" : "Enable"} colourblind mode`}>
            <Button onClick={() => setUsePatterns(!usePatterns)} icon={<Paint color={usePatterns ? "black" : "brand"} />} aria-label={`${usePatterns ? "Disable" : "Enable"} colourblind mode`} />
          </Tip>
          <Button onClick={handleDashboardEdit} icon={<Edit color="brand" />} aria-label={`Edit ${getDashboardResponse.data.name} dashboard`} />
        </div>
      )}
      showBackButton
    >
      {/* demographics mapbox popups */}
      <PopupsContainer
        map={map}
        mapLoaded={mapLoaded}
        active={activeTabIndex === 0}
        type={MapDataType.Demographics}
        councils={getDemographicsResponse.data?.councils.features}
        wards={getDemographicsResponse.data?.wards.features}
        setSelectedCouncil={setSelectedCouncil}
        setSelectedWard={setSelectedWard}
      />

      {/* politics mapbox popups */}
      <PopupsContainer
        map={map}
        mapLoaded={mapLoaded}
        active={activeTabIndex === 2}
        type={MapDataType.Politics}
        wards={getPoliticsResponse.data?.features}
      />

      <DetailGrid
        fill="vertical"
      >
        <Box fill="vertical" flex={{ grow: 1 }} ref={container.ref}/>
        <MetricsColumn fill="vertical" pad="medium" style={{ padding: "0rem" }}>
          <DashboardTabs activeIndex={activeTabIndex} onActive={setActiveTabIndex}>
            <DashboardTab
              tabIndex={0}
              title={(
                <Text as="h2" bold>
                  Demographics
                </Text>
              )}
              icon={<Briefcase />}
              aria-label="Demographics Tab"
            >
              <Demographics
                id={id}
                withBaseline={baselineDataReady}
                data={getDemographicsResponse.data}
                loading={getDemographicsResponse.pending}
                error={getDemographicsResponse.error}
                selectedCouncil={selectedCouncil}
                setSelectedCouncil={setSelectedCouncil}
                selectedWard={selectedWard}
                setSelectedWard={setSelectedWard}
                usePatterns={usePatterns}
              />
            </DashboardTab>
            <DashboardTab
              tabIndex={0}
              title={(
                <Text as="h2" bold>
                  Geography
                </Text>
              )}
              icon={<TreeOption />}
              aria-label="Geography Tab"
            >
              <Geography
                id={id}
                map={map}
                data={getGeographyResponse.data}
                error={getGeographyResponse.error}
              />
            </DashboardTab>
            <DashboardTab
              tabIndex={0}
              title={(
                <Text as="h2" bold>
                  Politics
                </Text>
              )}
              icon={<Navigate />}
              aria-label="Politics Tab"
            >
              <Politics
                id={id}
                data={getPoliticsResponse.data}
                usePatterns={usePatterns}
                error={getPoliticsResponse.error}
              />
            </DashboardTab>
          </DashboardTabs>
        </MetricsColumn>
      </DetailGrid>
    </PageLayout>
  );
};

const DetailGrid = styled(Box)`
  display: grid;

  @media (min-width: 769px) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 768px) {
    grid-template-rows: 50% 50%;
  }
`;

const MetricsColumn = styled(Box)`
  position: relative;
  label {
    margin-bottom: 1rem;
  }
  
  [role="tabpanel"] > div {
    padding: 0rem 1.5rem 1.5rem;
  }
`;

const DashboardTab = styled(Tab)`
  padding: 0.5rem 1rem;
  h2 {
    color: ${({ theme }) => theme.colors.brand};
    margin: 0.5rem;
    margin-left: 1rem;
  }
  svg {
    stroke: ${({ theme }) => theme.colors.brand};
  }

  &[aria-selected=true] {
    background-color: ${({ theme }) => theme.colors.brand};
    h2 {
      color: white;
    }
    svg {
      stroke: white;
    }
  }

  &:hover[aria-selected=false] {
    background-color: #F8F8F8;
    h2 {
      color: ${({ theme }) => theme.colors.brand};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.brand};
    }
  }

  & > div:first-child {
    border-bottom: none;
    padding-bottom: 0rem;
  }
`;

const DashboardTabs = styled(Tabs)`
  height: 100%;
  // header of tabs
  & > div:first-child {
    position: sticky;
    justify-content: flex-start;
    padding: 0;
    top: 0;
    background-color: white;
    border-bottom: 1px solid ${({ theme }) => theme.colors.brand};
  }

  // rendered content of each tab
  & > div:last-child {
    overflow-y: scroll;
    height: 100%;
  }
`;