import React from "react";
import {
  Box,
  Heading
} from "grommet";
import {
  Alert,
  MapLocation
} from "grommet-icons";

import { Text } from "src/components/text/Text";
import { ApiError } from "src/utils/api/types";

interface ErrorProps {
  error: ApiError
}

export const Error: React.FC<ErrorProps> = ({ error }) => {
  const errorIcon = React.useMemo(() => {
    if (error.details.includes("Query is incomplete")) {
      return <MapLocation size="xlarge" color="brand"/>;
    }

    return <Alert size="xlarge" color="warning"/>;
  }, [ error ]);

  const errorTitle = React.useMemo(() => {
    if (error.details.includes("401")) {
      return "Unauthorised";
    }

    if (error.details.includes("429")) {
      return "Too many requests";
    }

    if (error.details.includes("Query is incomplete")) {
      return "Data not available";
    }

    return error.title;
  }, [ error ]);

  const errorMessage = React.useMemo(() => {
    if (error.details.includes("Query is incomplete")) {
      return "This data is currently only available for England and Wales.";
    }

    return error.details;
  }, [ error ]);

  return (
  <Box fill align="center" justify="center" pad="medium">
    {errorIcon}
    <Heading textAlign="center">
      {errorTitle}
    </Heading>
    <Text as="span">
      {errorMessage}
    </Text>
  </Box>
  );
};