import { DefaultTheme } from "styled-components";

import { PartyName } from "src/models/PoliticsData";

export const politicalPartyColours: Record<string, string> = {
  [ PartyName.Conservative ]: "#0087DC",
  [ PartyName.LiberalDemocrats ]: "#FAA61A",
  [ PartyName.Labour ]: "#E4003B",
  [ PartyName.LabourAndCooperative ]: "#E4003B",
  [ PartyName.Green ]: "#6AB023",
  [ PartyName.ScottishGreen ]: "#6AB023",
  [ PartyName.UKIP ]: "#70147A",
  [ PartyName.DemocraticUnionistParty ]: "#D46A4C",
  [ PartyName.SinnFein ]: "#326760",
  [ PartyName.AllianceNI ]: "#F6CB2F",
  [ PartyName.SocialDemocraticLabourParty ]: "#2AA82C",
  [ PartyName.ProgressiveUnionistPartyNI ]: "#2B45A2",
  [ PartyName.UlterUnionistParty ]: "#48A5EE",
  [ PartyName.ScottishNationalParty ]: "#FDF38E",
  [ PartyName.PlaidCymru ]: "#005B54",
  [ PartyName.Independent ]: "#C8C8C8"
};

export const colours = {
  oxidised: "#85C489", // pale green
  midnightDive: "#000032", // dark blue
  whiteSwan: "#F2F2F2", // off-white
  pistacioMacaron: "#AFE68C", // lime green
  aquamarineDream: "#3CC8C8", // turquoise
  heatwave: "#F08262", // bright orange
  herb: "#739936", // muted darker green
  celadonPot: "#ACDBD1", // powder blue
  middaySun: "#F9E056", // bright yellow
  teal: "#3AB5A3", // teal
  flaxenLocks: "#B99F73", // pale brown
  britishSummer: "#65858A", // blue-grey
  elephantsBreath: "#71797B", // grey
  blueSteel: "#365C73", // dark blue
  deleteRed: "#ff4d4d" // lighter red
};

export const globalTheme: DefaultTheme = {
  breakpoints: { small: { value: 600 } },
  font: {
    family: "'Merriweather Sans', -apple-system, 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif",
    size: "1.4rem",
    height: "1.4"
  },
  colors: {
    // Copper primary palette
    primary: colours.oxidised,
    brand: colours.oxidised,
    active: colours.oxidised,
    white: colours.whiteSwan,
    black: colours.midnightDive,
    background: colours.whiteSwan,
    warning: colours.middaySun,
    error: colours.heatwave,
    text: {
      light: colours.midnightDive,
      dark: colours.whiteSwan
    }
  }
};

export interface ThemeType {
  breakpoints: {
    [key: string]: {
      value: number;
    }
  };
  font: {
    family: string;
    size: string;
    height: string;
  };
  colors: {
    primary: string;
    brand: string;
    active: string;
    white: string;
    black: string;
    background: string;
    warning: string;
    error: string;
    text: {
      light: string;
      dark: string;
    };
  };
}

export const copper = {
  global: globalTheme,
  card: {
    container: {
      elevation: "none",
      background: "light-1"
    }
  },
  tip: { content: { background: "white" } },
  anchor: { color: "black" }
};

export const copperCharts = [
  colours.blueSteel,
  colours.middaySun,
  colours.celadonPot,
  colours.flaxenLocks,
  colours.teal,
  colours.pistacioMacaron,
  colours.britishSummer,
  colours.herb,
  colours.heatwave
];