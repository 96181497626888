import mapboxgl from "mapbox-gl";

import {
  addMapLayer,
  hideMapLayer
} from "src/utils/map-data-helper";

const buildInFilter = (term: string) => [
  "in",
  term,
  [ "get", "SiteFunction" ]
];

const buildInFilters = (terms: string[]) => {
  if (terms.length === 1) {
    return buildInFilter(terms[ 0 ]);
  } else {
    return [ "any", ...terms.map(buildInFilter) ];
  }
};

export const educationFiltersByCategory: Record<string, string[]> = {
  education_schools: [ "Primary", "Secondary" ],
  education_furtherEducation: [ "Further" ],
  education_universities: [ "University" ],
  education_specialNeeds: [ "Special Needs" ]
};

const buildEducationLayer = (id: string): mapboxgl.AnyLayer => ({
  id,
  type: "fill",
  source: "education",
  paint: {
    "fill-color": "blue",
    "fill-opacity": 0.5
  },
  filter: buildInFilters(educationFiltersByCategory[ id ])
});

export const addEducationLayers = (map: mapboxgl.Map): void => {
  for (const categoryId in educationFiltersByCategory) {
    addMapLayer(map, buildEducationLayer(categoryId));
  }
};

export const hideEducationLayers = (map: mapboxgl.Map): void => {
  const layerIds = Object.keys(educationFiltersByCategory);

  layerIds.forEach(layerId => hideMapLayer(map, layerId));
};