import React from "react";
import mapboxgl from "mapbox-gl";

import { GeographyData } from "src/models/GeographyData";
import {
  addMapDataSource,
  LayerVisibility
} from "src/utils/map-data-helper";

import {
  addEducationLayers,
  addGreenspaceLayers,
  addHealthcareLayers,
  toggleGeographyLayer
} from "./utils/geography";

type UseGeographyMapFeatures = (
  map: mapboxgl.Map | undefined,
  mapLoaded: boolean,
  isActiveTab: boolean,
  geographyData: GeographyData | null
) => void;

export const useGeographyMapFeatures: UseGeographyMapFeatures = (
  map,
  mapLoaded,
  isActiveTab,
  geographyData
) => {
  React.useEffect(() => {
    if (map && mapLoaded && geographyData) {
      if (isActiveTab) {
        // greenspace
        addMapDataSource(map, "greenspace", geographyData.greenspace);
        addGreenspaceLayers(map);
        // healthcare
        addMapDataSource(map, "healthcare", geographyData.healthcare);
        addHealthcareLayers(map);
        // education
        addMapDataSource(map, "education", geographyData.education);
        addEducationLayers(map);

        // hide when tab loses focus
        return () => {
          toggleGeographyLayer(map, "greenspace", LayerVisibility.Hidden);
          toggleGeographyLayer(map, "healthcare", LayerVisibility.Hidden);
          toggleGeographyLayer(map, "education", LayerVisibility.Hidden);
        };
      }
    }
  }, [
    map,
    mapLoaded,
    geographyData,
    isActiveTab
  ]);
};