import { useAxios } from "src/hooks/useAxios";
import { Dashboard } from "src/models/Dashboard";

import {
  EndpointConfig,
  RequestConfig,
  UseApiRequest
} from "./types";

// request + response types

type NoParams = Record<string, never>;

interface DashboardPathParams {
  id: string;
}

interface CreateDashboardParams {
  name: string;
  latitude: number;
  longitude: number;
  radius: number;
}

type UpdateDashboardParams = DashboardPathParams & CreateDashboardParams;

type DashboardsEndpointConfig = {
  GET_DASHBOARD: EndpointConfig<
    DashboardPathParams,
    Dashboard
  >;
  GET_DASHBOARDS: EndpointConfig<
    NoParams,
    Dashboard[]
  >;
  CREATE_DASHBOARD: EndpointConfig<
    CreateDashboardParams,
    Dashboard
  >;
  UPDATE_DASHBOARD: EndpointConfig<
    UpdateDashboardParams,
    Dashboard
  >;
  DELETE_DASHBOARD: EndpointConfig<
    DashboardPathParams,
    null
  >;
};

// dashboards api specific logic

const requestInitialisers: RequestConfig<DashboardsEndpointConfig> = {
  GET_DASHBOARD: (
    ({ id }) => ({
      url: `/dashboards/${id}`,
      method: "GET"
    })
  ),
  GET_DASHBOARDS: (
    () => ({
      url: "/dashboards",
      method: "GET"
    })
  ),
  CREATE_DASHBOARD: (
    params => ({
      url: "/dashboards",
      method: "POST",
      data: params
    })
  ),
  UPDATE_DASHBOARD: (
    ({ id, ...params }) => ({
      url: `/dashboards/${id}`,
      method: "PUT",
      data: params
    })
  ),
  DELETE_DASHBOARD: (
    ({ id }) => ({
      url: `/dashboards/${id}`,
      method: "DELETE"
    })
  )
};

export const useDashboardsApi: UseApiRequest<DashboardsEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
