import {
  Box,
  Spinner
} from "grommet";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Parser } from "json2csv";
import JSZip from "jszip";
import { Download } from "grommet-icons";

import { Button } from "src/components/button/Button";
import { Card } from "src/components/card/Card";
import { Error } from "src/components/error/Error";
import { Tooltip } from "src/components/chart/Tooltip";
import { PoliticsData } from "src/models/PoliticsData";
import { ChartData } from "src/models/ChartData";
import { getChartDataFromPoliticsData } from "src/utils/chart-data-helper";
import { ChartTypes } from "src/models/ChartTypes";
import { ApiError } from "src/utils/api/types";
import { useChartTooltip } from "src/hooks/useTooltip";
import { useActiveDashboardSlug } from "src/hooks/useActiveDashboardSlug";

import democracyClubLogo from "../../assets/democracyclub-logo.png";

interface PoliticsProps {
  id: string;
  data: PoliticsData | null;
  usePatterns: boolean;
  error: ApiError | null;
}

export const Politics: React.FC<PoliticsProps> = ({
  id,
  data,
  usePatterns,
  error
}) => {
  const dashboardSlug = useActiveDashboardSlug();
  const [ tooltipData, tooltipConfig ] = useChartTooltip<"doughnut">();
  const [ electionResultChartData, setElectionResultChartData ] = React.useState<ChartData | null>(null);

  React.useMemo(() => {
    if (data) {
      setElectionResultChartData(getChartDataFromPoliticsData(data, ChartTypes.Pie, usePatterns));
    }
  }, [ data, usePatterns ]);

  const PoliticsDoughnut = React.useMemo(() => (
    <Doughnut
      data={electionResultChartData}
      options={{
        responsive: true,
        aspectRatio: 2.25,
        plugins: { tooltip: tooltipConfig }
      }}
    />
  ), [ electionResultChartData ]);

  const downloadCSV = () => {
    const json2csvParser = new Parser();

    if (electionResultChartData) {
      const electionResultsJson = electionResultChartData.datasets[ 0 ].data.map((dataPoint, index) => {
        return {
          label: electionResultChartData.labels[ index ],
          value: dataPoint
        };
      });

      const electionResultsCsv = json2csvParser.parse(electionResultsJson);
      const zip = new JSZip();

      zip.file("election-results.csv", electionResultsCsv);

      zip.generateAsync({ type: "blob" })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, `dashboard-${id}-politics.zip`);
        });
    }
  };

  if (error) {
    return (
      <Error error={error} />
    );
  }

  if (!data) {
    return (
      <Box align="center" fill="vertical" justify="center" alignSelf="center" margin="auto">
        <Spinner size="large" />
      </Box>
    );
  }

  return (
    <Box gap="medium" pad="small">
      <Button
        icon={<Download size="20px" color="brand" />}
        label="CSV"
        title="Download data as CSV"
        onClick={downloadCSV}
        // primary
        margin={{ top: "medium" }}
        style={{ alignSelf: "flex-end" }}
      />

      <Card
        headerText="Council Election Results by Political Party"
        bodyText="Wards not shown on the map have not had a local election within the past 5 years"
        fill="horizontal"
        dataSource={{
          logo: democracyClubLogo,
          name: "Candidates & Elections APIs",
          link: "https://democracyclub.org.uk"
        }}
        fileName={`election-results-${dashboardSlug}`}
      >
        {PoliticsDoughnut}
        <Tooltip data={tooltipData} dataType="count" />
      </Card>
    </Box>
  );
};