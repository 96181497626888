import React from "react";
import { Bar } from "react-chartjs-2";

import { Card } from "src/components/card/Card";
import { useActiveDashboardSlug } from "src/hooks/useActiveDashboardSlug";
import { useChartTooltip } from "src/hooks/useTooltip";
import {
  ChartData,
  ChartProps
} from "src/models/ChartData";
import {
  getBaselineData,
  getBaselineDataset
} from "src/utils/chart-data-helper";

import { Tooltip } from "./Tooltip";
import onsLogo from "../../assets/ons-logo.png";

export const IndustryBar: React.FC<ChartProps> = ({ data, withBaseline }) => {
  const dashboardSlug = useActiveDashboardSlug();
  const [ tooltipData, tooltipConfig ] = useChartTooltip<"bar">();
  const baselineData = React.useMemo(() => withBaseline ? getBaselineData("industry") : null, [ withBaseline ]);

  const chartData = React.useMemo(() => {
    if (baselineData) {
      const baselineDataset = getBaselineDataset(baselineData);

      if (data && baselineDataset) {
        const dataWithBaseline: ChartData = {
          ...data,
          datasets: [ ...data.datasets, baselineDataset ]
        };

        return dataWithBaseline;
      }

      return data;
    }
  }, [ baselineData ]);

  const BarChart = React.useMemo(() => (
    <Bar
      data={chartData}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: "y",
        scales: {
          y: {
            ticks: {
              font: { size: 11 },
              autoSkip: false,
              callback: truncateLabels
            }
          }
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 30,
              font: { size: 11 }
            }
          },
          tooltip: tooltipConfig
        }
      }}
    />
  ), [ data ]);

  return (
    <Card
      headerText="Industry (Workplace Population)"
      dataSource={{
        logo: onsLogo,
        name: "NOMIS WP605EW (2011)",
        link: "https://www.nomisweb.co.uk/query/construct/summary.asp?reset=yes&mode=construct&dataset=1314&version=0&anal=1&initsel="
      }}
      fill="horizontal"
      fileName={`industry-${dashboardSlug}`}
      cardSize="50vh"
    >
      {BarChart}
      <Tooltip data={tooltipData} baselineData={baselineData} />
    </Card>
  );
};

type BarType = typeof Bar;

interface BarChart extends BarType {
  getLabelForValue: (value: string) => string;
}

function truncateLabels(this: BarChart, value: string) {
  const label = this.getLabelForValue(value);

  return label.length > 32 ? label.slice(0, 32) + "…" : label;
}