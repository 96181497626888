import React from "react";
import {
  Box,
  Spinner
} from "grommet";
import { Parser } from "json2csv";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Download } from "grommet-icons";
import mapboxgl from "mapbox-gl";

import { Button } from "src/components/button/Button";
import { ApiError } from "src/utils/api/types";
import { LayerVisibility } from "src/utils/map-data-helper";
import { toggleGeographyLayer } from "src/hooks/map/utils/geography";
import { GeographyData } from "src/models/GeographyData";
import ordnanceSurveyLogo from "src/assets/ordnance-survey-logo.png";
import { CheckboxCard } from "src/components/card/CheckboxCard";
import { Error } from "src/components/error/Error";

interface GeographyProps {
  id: string;
  map: mapboxgl.Map | undefined;
  data: GeographyData | null;
  error: ApiError | null;
}

export const Geography: React.FC<GeographyProps> = ({
  id,
  map,
  data,
  error
}) => {
  const onCheckboxClick = (featureToUpdate: string, visibility: LayerVisibility) => {
    if (map) {
      toggleGeographyLayer(map, featureToUpdate, visibility);
    }
  };

  const downloadCSV = () => {
    const json2csvParser = new Parser();

    if (data) {
      const greenspaceCsv = json2csvParser.parse(data.greenspace);
      const healthcareCsv = json2csvParser.parse(data.healthcare);
      const educationCsv = json2csvParser.parse(data.education);
      const zip = new JSZip();

      zip.file("greenspace.csv", greenspaceCsv);
      zip.file("healthcare.csv", healthcareCsv);
      zip.file("education.csv", educationCsv);

      zip.generateAsync({ type: "blob" })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, `dashboard-${id}-geography.zip`);
        });
    }
  };

  if (error) {
    return (
      <Error error={error} />
    );
  }

  if (!data) {
    return (
      <Box align="center" fill="vertical" justify="center" alignSelf="center" margin="auto">
        <Spinner size="large" />
      </Box>
    );
  }

  return (
    <Box gap="medium" pad="small">
      <Button
        icon={<Download size="20px" color="brand" />}
        label="CSV"
        title="Download data as CSV"
        onClick={downloadCSV}
        // primary
        margin={{ top: "medium" }}
        style={{ alignSelf: "flex-end" }}
      />

      <CheckboxCard
        section="greenspace"
        headerText={`Greenspaces (${data.greenspace.features.length})`}
        checkboxOptions={{
          greenspace_parksAndGardens: "Public Parks & Gardens",
          greenspace_playAreas: "Play Areas",
          greenspace_growingSpaces: "Allotments & Community Growing Spaces",
          greenspace_sportsFacilities: "Sports Facilities",
          greenspace_religiousGrounds: "Religious Grounds & Cemeteries"
        }}
        onCheckboxClick={onCheckboxClick}
        dataSource={{
          logo: ordnanceSurveyLogo,
          name: "OS Open Zoomstack",
          link: "https://www.ordnancesurvey.co.uk/business-government/products/open-zoomstack"
        }}
      />

      <CheckboxCard
        section="healthcare"
        headerText={`Healthcare (${data.healthcare.features.length})`}
        checkboxOptions={{
          healthcare_hospitals: "Hospitals",
          healthcare_medicalCareAccommodation: "Hospices & Medical Care Accommodation"
        }}
        onCheckboxClick={onCheckboxClick}
        dataSource={{
          logo: ordnanceSurveyLogo,
          name: "OS MasterMap Sites Layer",
          link: "https://www.ordnancesurvey.co.uk/documents/os-mastermap-sites-layer-user-guide.pdf"
        }}
      />

      <CheckboxCard
        section="education"
        headerText={`Education (${data.education.features.length})`}
        options={data.education.features}
        checkboxOptions={{
          education_schools: "Schools",
          education_furtherEducation: "Further Education",
          education_universities: "Higher & University Education",
          education_specialNeeds: "Special Needs Education"
        }}
        onCheckboxClick={onCheckboxClick}
        dataSource={{
          logo: ordnanceSurveyLogo,
          name: "OS MasterMap Sites Layer",
          link: "https://www.ordnancesurvey.co.uk/documents/os-mastermap-sites-layer-user-guide.pdf"
        }}
      />
    </Box>
  );
};
