import React, { useState } from "react";
import {
  AuthState,
  onAuthUIStateChange
} from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

interface AuthDataState {
  authenticated: boolean;
  error: string | null;
  authenticating: boolean;
  idToken: string;
}

const initialAuthContext: AuthDataState = {
  authenticated: false,
  error: null,
  authenticating: true,
  idToken: ""
};

export interface AuthContextAttributes {
  authDataState: AuthDataState;
  setAuthDataState: (newState: AuthDataState) => void;
}

export const AuthContext = React.createContext<AuthContextAttributes>({
  authDataState: initialAuthContext,
  setAuthDataState: () => null
});

export const AuthProvider: React.FC = ({ children }) => {
  const [ authDataState, setAuthDataState ] = useState<AuthDataState>(initialAuthContext);

  React.useEffect(() => {
    // Check if the user is already authenticated
    Auth.currentAuthenticatedUser()
      .then(user =>
        setAuthDataState({
          authenticated: true,
          error: null,
          authenticating: false,
          idToken: user.signInUserSession.getIdToken().getJwtToken()
        }))
      .catch(err => setAuthDataState({
        authenticated: false,
        error: err,
        authenticating: false,
        idToken: ""
      }));

    onAuthUIStateChange(nextAuthState => {
      // if the user signed in but not already authenticated then authenticate
      if (nextAuthState === AuthState.SignedIn && !authDataState.authenticated) {
        Auth.currentSession()
          .then(session => setAuthDataState({
            authenticated: true,
            error: null,
            authenticating: false,
            idToken: session.getIdToken().getJwtToken()
          }));
      }
    });
  }, []);

  const contextValue: AuthContextAttributes = React.useMemo(() => ({
    authDataState,
    setAuthDataState
  }), [ authDataState ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};