import React from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader
} from "grommet";
import { Alert } from "grommet-icons";
import styled from "styled-components";

import { Text } from "src/components/text/Text";
import { Button } from "src/components/button/Button";

interface ConfirmationProps {
  title: string;
  text: string;
  onSubmit: () => void;
  submitText: string;
}

export const ErrorModal: React.FC<ConfirmationProps> = ({
  title, text, onSubmit, submitText
}) => {
  return (
    <Card width="large" pad="medium">
      <AlertIcon size="large" color="warning"/>
      <CardHeader direction="column" align="center">
        <Text as="h2" bold>
          {title}
        </Text>
      </CardHeader>
      <CardBody
        pad={{
          top: "small",
          bottom: "large"
        }}
        align="center"
      >
        <ErrorText as="span">
          {text}
        </ErrorText>
      </CardBody>
      <Box alignSelf="center">
        <Button primary label={submitText} onClick={onSubmit} />
      </Box>
    </Card>
  );
};

const AlertIcon = styled(Alert)`
  align-self: center;
  padding: 0.75rem;
`;

const ErrorText = styled(Text)`
  text-align: center;
`;