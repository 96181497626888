import React from "react";

type RefCallback = (el: HTMLElement | null) => void;

export interface MapboxContainer {
  ref: RefCallback,
  el: HTMLElement | undefined,
  ready: boolean
}

export const useMapboxContainer = (): MapboxContainer => {
  const [ ready, setReady ] = React.useState(false);
  const ref = React.useRef<HTMLElement>();

  const createRef = React.useCallback<RefCallback>(el => {
    if (el) {
      ref.current = el;
      setReady(true);
    }
  }, []);

  return {
    ref: createRef,
    el: ref.current,
    ready
  };
};